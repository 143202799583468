/*
 * @Author: chenxinyu
 * @Date: 2022-07-20 10:23:08
 * @LastEditTime: 2022-08-25 20:54:21
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/level/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useState, useEffect, useRef } from 'react';
import './index.scss';
import { getCheckPoint, getGame5 } from '../../api';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { BackBtn } from '../../components';

const Level: FC = () => {
    const [levelNum, setLevelNum] = useState<number>(0); // 关卡数
    const [isChuangGuan, setIsChuangGuan] = useState<boolean>(false); // 是闯关模式
    const audioRef = useRef<any>(); // 声音播放实例
    const navigate = useNavigate();

    /**
     * @description 拉取当前关卡数
     */
    const getLevel = async () => {
        let result = 1;
        const studentId1 = localStorage.getItem('studentId1');
        const studentId2 = localStorage.getItem('studentId2');

        const res: any = await getCheckPoint({ studentId: studentId1 });
        if (res.code === 200) {
            result = res.data;

            // 如果存在两个幼儿，则以关卡较小的为准
            if (studentId2) {
                const res2: any = await getCheckPoint({
                    studentId: studentId2
                });
                if (res.code === 200) {
                    if (result > res2.data) {
                        result = res2.data;
                    }
                    setLevelNum(result);
                }
            } else {
                setLevelNum(result);
            }
        }
    };

    useEffect(() => {
        // 播放音频
        audioRef.current.src = '';
        audioRef.current.src =
            'http://www.ctwlab.com/images/sounds/others/choose-level.mp3';
        audioRef.current.play();

        // 拉取level数据
        const mode = localStorage.getItem('mode');
        if (mode === 'recruit_mode') {
            // 闯关模式最新关卡
            getLevel();

            // 是闯关模式
            setIsChuangGuan(true);
        }
    }, []);

    /**
     * @description 选择关卡
     */
    const onLevelSelect = async (level: number) => {
        if (isChuangGuan && level > levelNum) {
            message.warning('尚未解锁！');

            return;
        }

        const action = localStorage.getItem('actionType');
        const studentId1 = localStorage.getItem('studentId1');
        const studentId2 = localStorage.getItem('studentId2');
        const params = {
            action,
            checkPoint: level.toString(),
            studentId: studentId1 + (studentId2 ? ',' + studentId2 : '')
        };
        const res: any = await getGame5(params);
        if (res.code === 200) {
            // 拉取动作
            const data = JSON.stringify(res.data);
            localStorage.setItem('actions', data);
            // 拉取批次ID
            const overStatus = localStorage.getItem('overStatus');
            if (!overStatus) {
                localStorage.setItem('batchNumber', res.data[0]['batchNumber']);
            }
            // 跳转游戏准备
            navigate('/gameReady');
        }
    };

    return (
        <>
            <div className="level">
                <div
                    className={
                        levelNum && isChuangGuan
                            ? `level-img level-img-${levelNum}`
                            : `level-img`
                    }>
                    <div
                        className="level-item level-item-1"
                        onClick={() => onLevelSelect(1)}></div>
                    <div
                        className="level-item level-item-2"
                        onClick={() => onLevelSelect(2)}></div>
                    <div
                        className="level-item level-item-3"
                        onClick={() => onLevelSelect(5)}></div>
                    <div
                        className="level-item level-item-4"
                        onClick={() => onLevelSelect(6)}></div>
                    <div
                        className="level-item level-item-5"
                        onClick={() => onLevelSelect(3)}></div>
                    <div
                        className="level-item level-item-6"
                        onClick={() => onLevelSelect(4)}></div>
                </div>
                <BackBtn />
            </div>

            <audio ref={audioRef} autoPlay></audio>
        </>
    );
};

export default memo(Level);
