/*
 * @Author: chenxinyu
 * @Date: 2022-07-31 19:35:58
 * @LastEditTime: 2022-08-21 11:12:18
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/medal/config.ts
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

export const medalConfig: any = {
    sports: [
        {
            checkPoint: 1,
            isUnlock: 0,
            starNum: 0,
            title: '第一关',
            imgUrl: 'http://www.ctwlab.com/images/medal/sports-1.png',
            unlockImg: 'http://www.ctwlab.com/images/medal/sports-1-unlock.png'
        },
        {
            checkPoint: 2,
            isUnlock: 0,
            starNum: 0,
            title: '第二关',
            imgUrl: 'http://www.ctwlab.com/images/medal/sports-2.png',
            unlockImg: 'http://www.ctwlab.com/images/medal/sports-2-unlock.png'
        },
        {
            checkPoint: 3,
            isUnlock: 0,
            starNum: 0,
            title: '第三关',
            imgUrl: 'http://www.ctwlab.com/images/medal/sports-3.png',
            unlockImg: 'http://www.ctwlab.com/images/medal/sports-3-unlock.png'
        },
        {
            checkPoint: 4,
            isUnlock: 0,
            starNum: 0,
            title: '第四关',
            imgUrl: 'http://www.ctwlab.com/images/medal/sports-4.png',
            unlockImg: 'http://www.ctwlab.com/images/medal/sports-4-unlock.png'
        },
        {
            checkPoint: 5,
            isUnlock: 0,
            starNum: 0,
            title: '第五关',
            imgUrl: 'http://www.ctwlab.com/images/medal/sports-5.png',
            unlockImg: 'http://www.ctwlab.com/images/medal/sports-5-unlock.png'
        },
        {
            checkPoint: 6,
            isUnlock: 0,
            starNum: 0,
            title: '第六关',
            imgUrl: 'http://www.ctwlab.com/images/medal/sports-6.png',
            unlockImg: 'http://www.ctwlab.com/images/medal/sports-6-unlock.png'
        }
    ],
    fun: [
        {
            checkPoint: 1,
            isUnlock: 0,
            starNum: 0,
            title: '第一关',
            imgUrl: 'http://www.ctwlab.com/images/medal/fun-1.png',
            unlockImg: 'http://www.ctwlab.com/images/medal/fun-1-unlock.png'
        },
        {
            checkPoint: 2,
            isUnlock: 0,
            starNum: 0,
            title: '第二关',
            imgUrl: 'http://www.ctwlab.com/images/medal/fun-2.png',
            unlockImg: 'http://www.ctwlab.com/images/medal/fun-2-unlock.png'
        },
        {
            checkPoint: 3,
            isUnlock: 0,
            starNum: 0,
            title: '第三关',
            imgUrl: 'http://www.ctwlab.com/images/medal/fun-3.png',
            unlockImg: 'http://www.ctwlab.com/images/medal/fun-3-unlock.png'
        }
    ],
    together: [
        {
            checkPoint: 1,
            isUnlock: 0,
            starNum: 0,
            title: '第一关',
            imgUrl: 'http://www.ctwlab.com/images/medal/together-1.png',
            unlockImg:
                'http://www.ctwlab.com/images/medal/together-1-unlock.png'
        }
    ]
};
