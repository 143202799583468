/*
 * @Author: chenxinyu
 * @Date: 2022-07-20 10:23:08
 * @LastEditTime: 2022-08-21 12:39:09
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/tabletFinish/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useEffect } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';

const TabletFinish: FC = () => {
    const navigate = useNavigate();

    /**
     * @description 5秒后自动返回
     */
    useEffect(() => {
        // 全屏显示
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        }

        setTimeout(() => {
            navigate('/tabletLogin');
        }, 5000);
    }, []);

    return (
        <div className="tablet-finish">
            <div className="tablet-finish-img">恭喜你，完成本轮打分！</div>
        </div>
    );
};

export default memo(TabletFinish);
