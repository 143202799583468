/*
 * @Author: chenxinyu
 * @Date: 2022-07-12 11:48:20
 * @LastEditTime: 2022-08-10 21:01:44
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/api/index.ts
 * Copyright (C) 2021 chenxinyu. All rights reserved.
 */

import request from '../utils/request';
import {
    loginURL,
    getStudentListURL,
    exportExcelUrl,
    importUrl,
    classURL,
    actionURL,
    game5URL,
    playBatch,
    rankURL,
    resultURL,
    numberURL,
    compairURL,
    initURL,
    tabletURL,
    gameStatusURL,
    scoreURL,
    playURL,
    checkPointUrl,
    medalURL,
    checkPointRecordURL,
    exportExcelStudentGameDataURL
} from './apiList';

/**
 * @description 登陆接口
 * @returns Promise
 */
export const login = (params: any) => {
    return request({
        url: loginURL,
        method: 'POST',
        data: params
    });
};

/**
 * @description 拉取幼儿列表接口
 * @returns Promise
 */
export const getStudentList = (params: any) => {
    return request({
        url: getStudentListURL,
        method: 'GET',
        params
    });
};

/**
 * @description 删除
 * @returns Promise
 */
export const deleteStudent = (params: any) => {
    return request({
        url: getStudentListURL + '/' + params.id,
        method: 'DELETE'
    });
};

/**
 * @description 拉取幼儿列表接口
 * @returns Promise
 */
export const editStudent = (params: any) => {
    return request({
        url: getStudentListURL + '/' + params.id,
        method: 'PUT',
        data: params
    });
};

/**
 * @description 拉取幼儿列表接口
 * @returns Promise
 */
export const exportExcel = () => {
    return request({
        url: exportExcelUrl,
        method: 'GET'
    });
};

/**
 * @description 导入数据
 * @returns Promise
 */
export const importExcel = (params: any) => {
    return request({
        url: importUrl,
        method: 'POST',
        data: params
    });
};

/**
 * @description 查询所有班级
 */
export const getClasses = () => {
    return request({
        url: classURL,
        method: 'GET'
    });
};

/**
 * @description 查询所有儿童
 * @returns Promise
 */
export const getStudents = (params: any) => {
    return request({
        url: classURL + '/' + params.studentClass,
        method: 'GET',
        params
    });
};

/**
 * @description 游戏
 */

/**
 * @description 查询动作库
 */
export const getAction = () => {
    return request({
        url: actionURL,
        method: 'GET'
    });
};

/**
 * @description 根据动作库关卡选择5个游戏动作
 */
export const getGame5 = (params: any) => {
    return request({
        url:
            game5URL +
            '/' +
            params.studentId +
            '/' +
            params.action +
            '/' +
            params.checkPoint,
        method: 'GET'
    });
};

/**
 * @description 导入数据
 */
export const postGamePlay = (params: any) => {
    return request({
        url: playBatch,
        method: 'POST',
        data: params
    });
};

/**
 * @description 查询排行榜
 */
export const getRank = (params: any) => {
    return request({
        url: rankURL + '/' + params.grade,
        method: 'GET'
    });
};

/**
 * @description 幼儿个人信息游戏结果,返回完成的游戏动作数量
 */
export const getTimes = (params: any) => {
    return request({
        url: resultURL + '/' + params.studentId,
        method: 'GET'
    });
};

/**
 * @description 每个游戏批次关卡游戏结束，返回游戏次数
 */
export const getNumber = (params: any) => {
    return request({
        url: numberURL + '/' + params.studentId + '/' + params.batchNumber,
        method: 'GET'
    });
};

/**
 * @description 两人游戏，比较年级，返回较小年级
 */
export const getCompair = (params: any) => {
    return request({
        url:
            compairURL +
            '/' +
            params.studentId1 +
            (params.studentId2 ? '/' + params.studentId2 : ''),
        method: 'GET'
    });
};

/**
 * @description 上传幼儿信息，返回年级；判断是否进入打分模式，以及游戏信息初始化(开始游戏)
 */
export const initGame = (params: any) => {
    return request({
        url: initURL + '/' + params.robotId + '/' + params.studentIdList,
        method: 'POST'
    });
};

/**
 * @description 平板获取幼儿
 */
export const getTabletChild = (params: any) => {
    return request({
        url: tabletURL + '/' + params.robotId,
        method: 'GET'
    });
};

/**
 * @description 更新幼儿的游戏状态：ready,go,over
 * @returns Promise
 */
export const gameStatus = (params: any) => {
    return request({
        url:
            gameStatusURL +
            '/' +
            params.studentId +
            '/' +
            params.oldGameStatus +
            '/' +
            params.newGameStatus,
        method: 'PUT'
    });
};

/**
 * @description 获取待打分的关卡动作
 */
export const getScoreAction = (params: any) => {
    return request({
        url: scoreURL + '/' + params.tabletStudentId,
        method: 'GET'
    });
};

/**
 * @description 每个动作游戏打分
 * @returns Promise
 */
export const score = (params: any) => {
    return request({
        url: scoreURL + '/' + params.actionId + '/' + params.starNum,
        method: 'PUT'
    });
};

/**
 * @description 每个游戏结束上传游戏批次号，动作库(运动会还有模式：畅玩play_freely_mode,闯关recruit_mode)，关卡，关卡序列号
 */
export const postGameResult = (params: any) => {
    return request({
        url: playURL,
        method: 'POST',
        data: params
    });
};

/**
 * @description 查询游戏关卡动作打分结果
 */
export const getGameScore = (params: any) => {
    return request({
        url: scoreURL + '/' + params.actionId,
        method: 'GET'
    });
};

/**
 * @description 获取幼儿运动会闯关模式最新关卡
 */
export const getCheckPoint = (params: any) => {
    return request({
        url: checkPointUrl + '/' + params.studentId,
        method: 'GET'
    });
};

/**
 * @description 查询游戏关卡动作打分结果
 */
export const getGameEndStars = (params: any) => {
    return request({
        url: playURL + '/' + params.ids,
        method: 'GET'
    });
};

/**
 * @description 查询幼儿个人勋章
 */
export const getChildMedal = (params: any) => {
    return request({
        url: medalURL + '/' + params.studentId + '/' + params.actionType,
        method: 'GET'
    });
};

/**
 * @description 幼儿游戏记录接口
 */
export const getCheckPointRecord = (params: any) => {
    return request({
        url: checkPointRecordURL + '/' + params.studentId + '/' + params.action,
        method: 'GET'
    });
};

/**
 * @description 查询勋章库
 */
export const getMedal = (params: any) => {
    return request({
        url: medalURL + '/' + params.studentId,
        method: 'GET'
    });
};

/**
 * @description 查询动作库对应游戏信息
 */
export const exportExcelStudentGameData = (params: any) => {
    return request({
        url: exportExcelStudentGameDataURL + '/' + params.studentClass,
        method: 'GET'
    });
};

/**
 * @description 查询幼儿的游戏状态：ready,go,over
 * @returns Promise
 */
export const getGameStatus = (params: any) => {
    return request({
        url: gameStatusURL + '/' + params.studentId,
        method: 'GET'
    });
};
