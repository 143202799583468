/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 09:55:24
 * @LastEditTime: 2022-08-06 15:07:17
 * @LastEditors: chenxinyu
 * @Description: 用户显示组件
 * @FilePath: /teacher-system/src/components/users/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';

const Users: FC<any> = (props: { notDetail: boolean }) => {
    const navigate = useNavigate();
    const [class1, setClass1] = useState<string>('');
    const [student1, setStudent1] = useState<string>('');
    const [profilePhoto1, setProfilePhoto1] = useState<string>('');
    const [class2, setClass2] = useState<string>('');
    const [student2, setStudent2] = useState<string>('');
    const [profilePhoto2, setProfilePhoto2] = useState<string>('');
    const [studentId1, setStudentId1] = useState<string>('');
    const [studentId2, setStudentId2] = useState<string>('');
    const { notDetail } = props;

    useEffect(() => {
        const class1Value = localStorage.getItem('class1');
        const student1Value = localStorage.getItem('student1');
        const studentId1Value = localStorage.getItem('studentId1');
        const profilePhoto1Value = localStorage.getItem('profilePhoto1');

        const class2Value = localStorage.getItem('class2');
        const student2Value = localStorage.getItem('student2');
        const studentId2Value = localStorage.getItem('studentId2');
        const profilePhoto2Value = localStorage.getItem('profilePhoto2');

        setClass1(class1Value!);
        setStudent1(student1Value!);
        setStudentId1(studentId1Value!);
        setProfilePhoto1(profilePhoto1Value!);
        setClass2(class2Value!);
        setStudent2(student2Value!);
        setStudentId2(studentId2Value!);
        setProfilePhoto2(profilePhoto2Value!);
    }, []);

    /**
     * @description 点击用户头像
     */
    const onUserClick = (studentId: string) => {
        if (notDetail) {
            return;
        }
        localStorage.setItem('profileId', studentId);
        navigate('/profile');
    };

    return (
        <div className="choose">
            {class1 && student1 && (
                <div
                    className="choose-item"
                    onClick={() => onUserClick(studentId1)}>
                    {profilePhoto1 ? (
                        <img src={profilePhoto1} alt="" />
                    ) : (
                        <div className="choose-item-icon"></div>
                    )}
                    {`${class1} ${student1}`}
                </div>
            )}
            {class2 && student2 && (
                <div
                    className="choose-item"
                    onClick={() => onUserClick(studentId2)}>
                    {profilePhoto2 ? (
                        <img src={profilePhoto2} alt="" />
                    ) : (
                        <div className="choose-item-icon"></div>
                    )}
                    {`${class2} ${student2}`}
                </div>
            )}
        </div>
    );
};

export default memo(Users);
