/*
 * @Author: chenxinyu
 * @Date: 2022-07-11 11:10:09
 * @LastEditTime: 2022-08-15 15:14:39
 * @LastEditors: chenxinyu
 * @Description: 登陆页面
 * @FilePath: /teacher-system/src/views/login/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../api';
import { message } from 'antd';
import './index.scss';

const Login: FC = () => {
    const navigate = useNavigate();
    const [name, setName] = useState<string>(''); // 用户名
    const [password, setPassword] = useState<string>(''); // 密码

    /**
     * @description 监听用户名输入
     */
    const onAccountChange = (e: { target: { value: string } }) => {
        let value = e.target.value;
        setName(value);
    };

    /**
     * @description 监听密码输入
     */
    const onPswChange = (e: { target: { value: string } }) => {
        let value = e.target.value;
        setPassword(value);
    };

    /**
     * @description 登陆事件
     */
    const onSubmit = async (pageName: string) => {
        if (!name) {
            message.error('用户名不能为空！');
            return;
        } else if (!password) {
            message.error('密码不能为空！');
            return;
        }

        const params = {
            name,
            password
        };
        const res: any = await login(params);
        const { code } = res;

        if (code === 200) {
            // 前端缓存记录用户登陆态
            localStorage.setItem('robotName', name);
            localStorage.setItem('robotId', res?.data?.id);
            localStorage.setItem('roleName', res?.data?.roleName);
            console.log('pageName=', pageName);
            if (pageName === 'leaderBoard') {
                console.log('leaderBoard');
                localStorage.setItem('loginPage', 'g');
            } else if (pageName === 'robot/childrenManagement') {
                localStorage.setItem('loginPage', 'm');
            } else if (pageName === 'tabletLogin') {
                localStorage.setItem('loginPage', 't');
            }
            navigate('/' + pageName);
        }
    };

    useEffect(() => {
        // 如果保存有登陆态信息，则直接进入
        const robotId = localStorage.getItem('robotId');
        const roleName = localStorage.getItem('roleName') || '';
        if (robotId === '1' || roleName.indexOf('小露珠') > -1) {
            const robotName = localStorage.getItem('robotName');
            const loginPage = localStorage.getItem('loginPage');
            if (robotName) {
                if (loginPage === 'm') {
                    navigate('/robot/childrenManagement');
                } else if (loginPage === 'g') {
                    navigate('/leaderBoard');
                } else if (loginPage === 't') {
                    navigate('/tabletLogin');
                }
            }
        }
    });

    return (
        <div className="login">
            <div className="login-green">
                <div className="login-word">
                    <div className="login-word-title">运动口令机器人</div>
                    <div className="login-word-title">信息中心</div>
                    <div className="login-word-sub">{`幼儿园教师登陆 >`}</div>
                </div>
                <div className="login-input">
                    <input
                        className="login-input-account"
                        placeholder="用户名"
                        onChange={onAccountChange}
                    />
                    <input
                        type="password"
                        className="login-input-psw"
                        placeholder="密码"
                        onChange={onPswChange}
                    />
                    <div className="login-input-btns">
                        <div
                            className="login-input-btn login-input-other"
                            onClick={() => onSubmit('leaderBoard')}>
                            <span>一体机登陆</span>
                        </div>
                        <div
                            className="login-input-btn login-input-other"
                            onClick={() => onSubmit('tabletLogin')}>
                            <span>平板登陆</span>
                        </div>
                        <div
                            className="login-input-btn"
                            onClick={() =>
                                onSubmit('robot/childrenManagement')
                            }>
                            <div className="login-input-btn-icon"></div>
                            <span>后台登录</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(Login);
