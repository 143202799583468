/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 09:55:24
 * @LastEditTime: 2022-07-27 19:32:05
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/chooseMode/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Users } from '../../components';
// import { getAction } from '../../api';
import './index.scss';

const ChooseMode: FC = () => {
    const navigate = useNavigate();
    const audioRef = useRef<any>(); // 声音播放实例

    /**
     * @description 畅玩模式
     */
    const onPlayFree = () => {
        localStorage.setItem('mode', 'play_freely_mode');
        navigate('/level');
    };

    /**
     * @description 闯关模式
     */
    const onPlayLevel = () => {
        localStorage.setItem('mode', 'recruit_mode');
        navigate('/level');
    };

    /**
     * @description 获取所有动作
     */
    const getActionNetwork = async () => {
        // const res = await getAction();
    };

    useEffect(() => {
        // 播放音频
        audioRef.current.src = '';
        audioRef.current.src =
            'http://www.ctwlab.com/images/sounds/others/choose-mode.mp3';
        audioRef.current.play();

        // 获取动作库
        getActionNetwork();
    }, []);

    const goBack = () => {
        navigate('/chooseAction');
    };

    return (
        <div className="choose-mode">
            <Users />
            <div className="selection">
                <div className="selection-title">选择模式</div>
                <div
                    className="selection-item selection-base"
                    onClick={onPlayFree}>
                    <div className="selection-base-icon"></div>
                    <div className="selection-item-title">畅玩模式</div>
                </div>
                <div
                    className="selection-item selection-interest"
                    onClick={onPlayLevel}>
                    <div className="selection-interest-icon"></div>
                    <div className="selection-item-title">闯关模式</div>
                </div>
            </div>
            <div className="back" onClick={goBack}>
                <div className="back-icon"></div>
            </div>
            <audio ref={audioRef} autoPlay></audio>
        </div>
    );
};

export default memo(ChooseMode);
