/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 10:32:28
 * @LastEditTime: 2022-08-06 18:23:33
 * @LastEditors: chenxinyu
 * @Description: 选择班级
 * @FilePath: /teacher-system/src/views/chooseClass/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useState, useEffect, useRef } from 'react';
import './index.scss';
import { getClasses } from '../../api';
import { useNavigate } from 'react-router-dom';
import { BackBtn } from '../../components';

const ChooseClass: FC = () => {
    const [data, setData] = useState<any>([]); // 列表数据
    const navigate = useNavigate();
    const audioRef = useRef<any>(); // 声音播放实例

    /**
     * @description 拉取列表
     */
    const getList = async () => {
        const res: any = await getClasses();
        if (res.code === 200) {
            setData(res.data);
        }
    };

    useEffect(() => {
        // 播放音频
        audioRef.current.src = '';
        audioRef.current.src =
            'http://www.ctwlab.com/images/sounds/others/choose-class.mp3';
        audioRef.current.play();

        getList();
    }, []);

    /**
     * @description 跳转选择儿童页面
     */
    const goChooseChildren = (title: string) => {
        navigate('/chooseChildren/' + title);
    };

    return (
        <div className="choose-class">
            <div className="class-title">请选择你的班级</div>
            <div className="class-container">
                <div className="class-group">
                    {data?.smallClass?.map((item: any, index: number) => {
                        return (
                            <div
                                className="class-group-item"
                                onClick={() => goChooseChildren(item)}
                                key={index}>
                                {item}
                            </div>
                        );
                    })}
                </div>
                <div className="class-group">
                    {data?.middleClass?.map((item: any, index: number) => {
                        return (
                            <div
                                className="class-group-item"
                                onClick={() => goChooseChildren(item)}
                                key={index}>
                                {item}
                            </div>
                        );
                    })}
                </div>
                <div className="class-group">
                    {data?.largeClass?.map((item: any, index: number) => {
                        return (
                            <div
                                className="class-group-item"
                                onClick={() => goChooseChildren(item)}
                                key={index}>
                                {item}
                            </div>
                        );
                    })}
                </div>
            </div>
            <BackBtn />
            <audio ref={audioRef} autoPlay></audio>
        </div>
    );
};

export default memo(ChooseClass);
