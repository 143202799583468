/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 21:37:01
 * @LastEditTime: 2022-08-21 17:35:07
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/game/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useState, useRef } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { Users } from '../../components';
import { useEffect } from 'react';
import { imgConfig } from '../../config';
import { getGameEndStars, postGameResult } from '../../api';

const Game: FC = () => {
    const navigate = useNavigate();
    const [time, setTime] = useState<number>(); // 倒数计时
    const [currentAction, setCurrentAction] = useState<any>(); // 当前动作
    const [currentDescribe, setCurrentDescribe] = useState<string>(''); // 当前动作描述
    const [currentImg, setCurrentImg] = useState<string>(''); // 当前动作图片

    const [showPic, setShowPic] = useState<boolean>(false); // 是否展示动作图片
    const audioRef = useRef<any>(); // 声音播放实例
    const backgroundAudioRef = useRef<any>(); // 背景声音播放实例
    const [isScoring, setIsScoring] = useState<boolean>(false); // 是否正在打分

    /**
     * @description 设置图片和音频
     */
    const setPicAndSound = (actionList: Array<any>, index: number) => {
        const currentItem = actionList[index];
        actionList.length > 0 && setCurrentAction(currentItem);

        // 动作描述
        const describe = currentItem?.checkPointDescribe;
        setCurrentDescribe(describe);

        // 动作图片
        const actionType = localStorage.getItem('actionType')!;
        const imgUrl = `http://www.ctwlab.com/images/actions/${actionType}/${actionType}-${
            currentItem?.checkPoint
        }-${currentItem?.checkPointSerialNumber}.${
            imgConfig[actionType]?.[currentItem?.checkPoint - 1]?.indexOf(
                currentItem?.checkPointSerialNumber
            ) > -1
                ? 'gif'
                : 'png'
        }`;
        setCurrentImg(imgUrl);

        // 动作音频
        const soundUrl = `http://www.ctwlab.com/images/sounds/${actionType}/${actionType}-${currentItem?.checkPoint}-${currentItem?.checkPointSerialNumber}.mp3`;

        audioRef.current.src = '';
        audioRef.current.src = soundUrl;
        audioRef.current.play();
    };

    /**
     * @description 显示倒计时页面
     */
    const showCountdownPage = (isOver: boolean = false) => {
        // 评委请打分音频
        const soundUrl = `http://www.ctwlab.com/images/sounds/others/give-stars.mp3`;

        audioRef.current.src = '';
        audioRef.current.src = soundUrl;
        audioRef.current.play();
        setCurrentDescribe('请打分');
        setTimeout(() => {
            setIsScoring(true);
            scoreInterval(0, 8, isOver);
        }, 2000);
    };

    /**
     * @description 记录两个儿童的actionIdList
     */
    const remarkActionIdList = (
        studentNum: number,
        currentActionId: string
    ) => {
        // 当前动作id
        if (studentNum === 1) {
            localStorage.setItem('currentActionId1', currentActionId);
        } else if (studentNum === 2) {
            localStorage.setItem('currentActionId2', currentActionId);
        }

        // 记录上传动作组id
        if (studentNum === 1) {
            const currentActionIdList = localStorage.getItem(
                'currentActionIdList1'
            );

            if (currentActionIdList) {
                localStorage.setItem(
                    'currentActionIdList1',
                    currentActionIdList + ',' + currentActionId
                );
            } else {
                localStorage.setItem('currentActionIdList1', currentActionId!);
            }
        } else if (studentNum === 2) {
            const currentActionIdList = localStorage.getItem(
                'currentActionIdList2'
            );

            if (currentActionIdList) {
                localStorage.setItem(
                    'currentActionIdList2',
                    currentActionIdList + ',' + currentActionId
                );
            } else {
                localStorage.setItem('currentActionIdList2', currentActionId!);
            }
        }
    };

    /**
     * @description 上传游戏结果
     */
    const postResult = async (studentId: string, studentNum: number) => {
        const actionLibraryName = localStorage.getItem('actionType')!;
        const batchNumber = localStorage.getItem('batchNumber')!;
        const actions = localStorage.getItem('actions')!;
        const actionList = JSON.parse(actions);
        const storeIndex = sessionStorage.getItem('storeIndex');
        const modeType = localStorage.getItem('mode')!;

        const params = {
            actionLibraryName,
            batchNumber,
            checkPoint: actionList[storeIndex!].checkPoint,
            checkPointSerialNumber:
                actionList[storeIndex!].checkPointSerialNumber,
            id: 0,
            modeType,
            studentId
        };

        const res: any = await postGameResult(params);
        if (res.code === 200) {
            // 记录上传动作id
            const currentActionId = res.data.id;

            // 记录两个儿童的actionIdList
            remarkActionIdList(studentNum, currentActionId);
        }
    };

    /**
     * @description 三秒倒计时准备，下一个动作开始
     */
    const waitingStart = (index: number = 0) => {
        const actions = localStorage.getItem('actions')!;
        const actionList = JSON.parse(actions);
        const grade = localStorage.getItem('grade');

        // 播放倒数3秒的音频
        audioRef.current.src = '';
        audioRef.current.src =
            'http://www.ctwlab.com/images/sounds/others/time-321.mp3';
        audioRef.current.play();

        // 4秒后,下一个游戏动作
        setTimeout(() => {
            if (grade === '大班') {
                // 倒计时结束：下一个游戏动作
                const storeIndex = sessionStorage.getItem('storeIndex')!;
                const restTime = actionList[Number(storeIndex) + 1]['seconds'];
                setIsScoring(false);
                timeInterval(Number(storeIndex) + 1, restTime, actionList);
            } else if (grade === '小班') {
                setIsScoring(false);
                timeInterval(
                    index + 1,
                    actionList[index + 1]['seconds'],
                    actionList
                );
            }
        }, 4000);
    };

    /**
     * @description 打分倒计时
     * @param index 序列号
     * @param restTime 剩余时间
     * @param actionList 动作序列
     */
    const scoreInterval = (
        index: number,
        restTime: number,
        isOver: boolean
    ) => {
        let timer = setInterval(async () => {
            setTime(restTime);
            restTime--;

            // 查询游戏关卡动作打分结果
            const actionId1 = localStorage.getItem('currentActionId1');
            const res: any = await getGameEndStars({ ids: actionId1 });

            if (
                res.code === 200 &&
                res.data &&
                res.data[0] &&
                res.data[0].starNum
            ) {
                // 学生2id
                const studentId2 = localStorage.getItem('studentId2');
                if (studentId2) {
                    const actionId2 = localStorage.getItem('currentActionId2');
                    const res2: any = await getGameEndStars({
                        ids: actionId2
                    });

                    // 存在第二个学生
                    if (
                        res2.code === 200 &&
                        res2.data &&
                        res2.data[0] &&
                        res2.data[0].starNum
                    ) {
                        // 已经打分，倒计时截止
                        clearInterval(timer);
                        // 三秒倒计时准备，下一个动作开始
                        isOver ? gameOver() : waitingStart();
                        return;
                    }
                } else {
                    // 已经打分，倒计时截止
                    clearInterval(timer);
                    // 三秒倒计时准备，下一个动作开始
                    isOver ? gameOver() : waitingStart();
                    return;
                }
            }

            // 未打分，倒计时继续
            if (restTime <= -1) {
                // 打分倒计时结束
                clearInterval(timer);
                // 三秒倒计时准备，下一个动作开始
                isOver ? gameOver() : waitingStart();
            }
        }, 1000);
    };

    /**
     * @description 游戏结束
     */
    const gameOver = () => {
        backgroundAudioRef.current.src = '';
        navigate('/gameEnd');
    };

    /**
     * @description 动作倒计时
     * @param index 序列号
     * @param restTime 剩余时间
     * @param actionList 动作序列
     */
    const timeInterval = (
        index: number,
        restTime: number,
        actionList: Array<any>
    ) => {
        // 是否正在打分
        setIsScoring(false);
        // 存储动作编号
        sessionStorage.setItem('storeIndex', index.toString());
        // 画面显示
        setPicAndSound(actionList, index);

        setTimeout(() => {
            let timer = setInterval(() => {
                setTime(restTime);
                restTime--;

                if (restTime === -1) {
                    clearInterval(timer);
                    const grade = localStorage.getItem('grade');
                    if (index < actionList.length - 1) {
                        if (grade === '大班') {
                            // 上传游戏结果
                            const studentId1 =
                                localStorage.getItem('studentId1');
                            const studentId2 =
                                localStorage.getItem('studentId2');
                            studentId1 && postResult(studentId1, 1);
                            studentId2 && postResult(studentId2, 2);

                            // 大班：动作结束后显示打分倒计时
                            showCountdownPage();
                        } else if (grade === '小班') {
                            // 小班：动作结束后，等三秒，下一个动作
                            setIsScoring(true);
                            waitingStart(index);
                        }
                    } else {
                        if (grade === '大班') {
                            // 上传游戏结果
                            const studentId1 =
                                localStorage.getItem('studentId1');
                            const studentId2 =
                                localStorage.getItem('studentId2');
                            studentId1 && postResult(studentId1, 1);
                            studentId2 && postResult(studentId2, 2);

                            // 大班：动作结束后显示打分倒计时
                            showCountdownPage(true);
                        } else if (grade === '小班') {
                            // 小班结束
                            gameOver();
                        }
                    }
                }
            }, 1000);
        }, 2000);
    };

    const timeout = async (actionList: Array<any>) => {
        setIsScoring(true);
        timeInterval(0, actionList[0]['seconds'], actionList);
    };

    useEffect(() => {
        // 1.播放背景音乐
        // backgroundAudioRef.current.src = '';
        // backgroundAudioRef.current.src =
        //     'http://www.ctwlab.com/images/sounds/others/action-background.mp3';
        // backgroundAudioRef.current.loop = true;
        // backgroundAudioRef.current.play();

        // 2.取出动作
        const actions = localStorage.getItem('actions')!;
        if (actions) {
            const actionList = JSON.parse(actions);

            // 3.配置是否展示动作图片
            if (actionList?.length) {
                const checkPointValue = actionList[0].checkPoint;
                const actionLibraryNameValue = actionList[0].actionLibraryName;
                setShowPic(
                    (checkPointValue < 5 &&
                        actionLibraryNameValue === 'sports') ||
                        actionLibraryNameValue === 'fun' ||
                        actionLibraryNameValue === 'together'
                );

                backgroundAudioRef.current.src = '';
                if (
                    actionLibraryNameValue === 'fun' &&
                    Number(checkPointValue) === 3
                ) {
                    backgroundAudioRef.current.src =
                        'http://www.ctwlab.com/images/sounds/others/gongfu-background.mp3';
                } else {
                    backgroundAudioRef.current.src =
                        'http://www.ctwlab.com/images/sounds/others/action-background.mp3';
                }
                backgroundAudioRef.current.loop = true;
                backgroundAudioRef.current.play();
            }

            // 4.开始游戏
            timeout(actionList);
        }
    }, []);

    return (
        <div className="game">
            <Users notDetail />
            <div className="game-content">
                <div className="game-content-title">{currentDescribe}</div>
                <div className="game-content-border">
                    <div
                        className={
                            isScoring
                                ? 'game-content-border-icon game-content-border-icon-finish'
                                : 'game-content-border-icon'
                        }>
                        {showPic ? (
                            <img
                                className="game-content-border-icon-img"
                                src={currentImg}
                                alt=""
                            />
                        ) : (
                            <span>{time}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="game-time">
                <div className="game-time-icon"></div>
                {showPic ? time : ''}
            </div>
            <audio ref={audioRef} autoPlay></audio>
            <audio ref={backgroundAudioRef} autoPlay></audio>
        </div>
    );
};

export default memo(Game);
