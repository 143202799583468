/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 21:37:01
 * @LastEditTime: 2022-08-25 22:20:03
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/medal/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useState, useRef } from 'react';
import './index.scss';
import { Users } from '../../components';
import { useEffect } from 'react';
import { getChildMedal } from '../../api';
import { medalConfig } from './config';
import { useNavigate } from 'react-router-dom';

const Medal: FC = () => {
    const [selectedAction, setSelectedAction] = useState<string>('sports'); // 选择动作库
    const [data, setData] = useState<Array<any>>(medalConfig['sports']); // 勋章
    const audioRef = useRef<any>(); // 声音播放实例
    const navigate = useNavigate();

    /**
     * @description 查询幼儿个人勋章
     */
    const getMedals = async (actionType: string) => {
        const studentId = localStorage.getItem('profileId');
        const params = {
            studentId,
            actionType
        };
        const res: any = await getChildMedal(params);
        if (res.code === 200) {
            const mData = res.data;
            const copyData = [...medalConfig[actionType]];
            copyData.map((item) => {
                let tempItem = item;
                mData.forEach((mItem: any) => {
                    if (mItem.checkPoint === item.checkPoint) {
                        tempItem.starNum = mItem.starNum;
                        tempItem.isUnlock = mItem.isUnlock;
                    }
                });
                return tempItem;
            });
            setData(copyData);
        }
    };

    useEffect(() => {
        // 播放倒数3秒的音频
        audioRef.current.src = '';
        audioRef.current.src =
            'http://www.ctwlab.com/images/sounds/others/medal.mp3';
        audioRef.current.play();

        getMedals('sports');
    }, []);

    /**
     * @description 选择动作库
     */
    const onActionClicked = (actionType: string) => {
        setSelectedAction(actionType);
        setData(medalConfig[actionType]);
        getMedals(actionType);
    };

    /**
     * @description 返回按钮
     */
    const onBack = () => {
        navigate(-1);
    };

    return (
        <div className="medal">
            <div className="medal-header">
                <div className="medal-header-person">
                    <Users />
                </div>
                <div className="medal-header-title">我的勋章</div>
            </div>
            <div className="medal-tab">
                <div
                    className="medal-tab-item medal-tab-sports"
                    onClick={() => onActionClicked('sports')}>
                    运动会
                    {selectedAction === 'sports' && (
                        <div className="medal-tab-item-selected"></div>
                    )}
                </div>
                <div
                    className="medal-tab-item medal-tab-fun"
                    onClick={() => onActionClicked('fun')}>
                    大剧场
                    {selectedAction === 'fun' && (
                        <div className="medal-tab-item-selected"></div>
                    )}
                </div>
                <div
                    className="medal-tab-item medal-tab-together"
                    onClick={() => onActionClicked('together')}>
                    合作台
                    {selectedAction === 'together' && (
                        <div className="medal-tab-item-selected"></div>
                    )}
                </div>
            </div>
            <div className="medal-container">
                {data.map((item, index) => {
                    return (
                        <div className="medal-container-item" key={index}>
                            <img
                                src={
                                    item.isUnlock === 1
                                        ? item.imgUrl
                                        : item.unlockImg
                                }
                                className={
                                    item.isUnlock === 1
                                        ? 'medal-container-item-img'
                                        : 'medal-container-item-img medal-container-item-img-unlock'
                                }
                                alt=""
                            />
                            <div className="medal-container-item-title">
                                {`${item.title}  ${item.starNum}/60`}
                                <img
                                    src="http://www.ctwlab.com/images/game/star.png"
                                    className="medal-container-item-title-star"
                                    alt=""
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="back-icon" onClick={onBack}></div>
            <audio ref={audioRef} autoPlay></audio>
        </div>
    );
};

export default memo(Medal);
