/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 09:55:24
 * @LastEditTime: 2022-08-25 20:53:44
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/fun/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getGame5 } from '../../api';
import { Users } from '../../components';
import './index.scss';

const Fun: FC = () => {
    const navigate = useNavigate();
    const audioRef = useRef<any>(); // 声音播放实例

    /**
     * @description 跳转关卡页面
     */
    const onCheckPoint = async (level: number) => {
        const action = localStorage.getItem('actionType');
        const studentId1 = localStorage.getItem('studentId1');
        const studentId2 = localStorage.getItem('studentId2');
        const params = {
            action,
            checkPoint: level.toString(),
            studentId: studentId1 + (studentId2 ? ',' + studentId2 : '')
        };
        const res: any = await getGame5(params);
        if (res.code === 200) {
            // 拉取动作
            const data = JSON.stringify(res.data);
            localStorage.setItem('actions', data);
            // 拉取批次ID
            const overStatus = localStorage.getItem('overStatus');
            if (!overStatus) {
                localStorage.setItem('batchNumber', res.data[0]['batchNumber']);
            }
            // 跳转游戏准备
            navigate('/gameReady');
        }
    };

    useEffect(() => {
        // 播放音频
        audioRef.current.src = '';
        audioRef.current.src =
            'http://www.ctwlab.com/images/sounds/others/choose-mode.mp3';
        audioRef.current.play();

        // 获取动作库
        // getActionNetwork();
    }, []);

    const goBack = () => {
        navigate('/childrenLogin');
    };

    return (
        <div className="fun">
            <Users />
            <div className="fun-selection">
                <div
                    className="fun-selection-item fun-selection-base"
                    onClick={() => onCheckPoint(1)}>
                    <div className="fun-selection-base-icon"></div>
                    <div className="fun-selection-item-title">
                        动物园里有什么
                    </div>
                </div>
                <div
                    className="fun-selection-item fun-selection-interest"
                    onClick={() => onCheckPoint(2)}>
                    <div className="fun-selection-interest-icon"></div>
                    <div className="fun-selection-item-title">我有小情绪</div>
                </div>
                <div
                    className="fun-selection-item fun-selection-together"
                    onClick={() => onCheckPoint(3)}>
                    <div className="fun-selection-together-icon"></div>
                    <div className="fun-selection-item-title">小小功夫</div>
                </div>
            </div>
            <div className="back" onClick={goBack}>
                <div className="back-icon"></div>
            </div>
            <audio ref={audioRef} autoPlay></audio>
        </div>
    );
};

export default memo(Fun);
