/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 21:37:01
 * @LastEditTime: 2022-08-21 11:51:57
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/profile/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useState } from 'react';
import './index.scss';
import { Users } from '../../components';
import { useEffect } from 'react';
import { getTimes } from '../../api';
import { useNavigate } from 'react-router-dom';

const Profile: FC = () => {
    const [times, setTimes] = useState<number>(1); // 游戏次数
    const [grade, setGrade] = useState<string>(''); // 游戏次数
    const navigate = useNavigate();

    /**
     * @description 获取游戏次数
     */
    const getTimesNetwork = async () => {
        const studentId = localStorage.getItem('profileId');
        const params = {
            studentId
        };
        const res: any = await getTimes(params);
        if (res.code === 200) {
            if (res.data === 0) {
                setTimes(1);
                return;
            }

            setTimes(
                Math.floor(res.data / 10) >= 20
                    ? 20
                    : Math.floor(res.data / 10) === 0
                    ? 1
                    : Math.floor(res.data / 10)
            );
        }
    };

    useEffect(() => {
        // 获取游戏次数
        getTimesNetwork();

        // 大班/小班
        const gradeVal = localStorage.getItem('grade');
        setGrade(gradeVal || '');
    }, []);

    const goMedal = () => {
        navigate('/medal');
    };

    /**
     * @description 返回按钮
     */
    const onBack = () => {
        navigate(-1);
    };

    return (
        <div
            className="profile"
            style={{
                backgroundImage: `url('http://www.ctwlab.com/images/profiles/profile_${times}.png')`,
                backgroundSize: `100% 100%`
            }}>
            <Users />
            {grade === '大班' && (
                <img
                    src="http://www.ctwlab.com/images/medal/sports-1.png"
                    className="profile-medal"
                    alt=""
                    onClick={goMedal}
                />
            )}
            <div className="back-icon" onClick={onBack}></div>
        </div>
    );
};

export default memo(Profile);
