/*
 * @Author: chenxinyu
 * @Date: 2022-07-13 13:21:04
 * @LastEditTime: 2022-08-21 12:36:56
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/index.tsx
 * Copyright (C) 2021 chenxinyu. All rights reserved.
 */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router';
import {
    Login,
    Robot,
    ChildrenMgmt,
    LeaderBoard,
    ChooseChildren,
    ChooseClass,
    ChildrenLogin,
    ChooseAction,
    CheckPoint,
    GameReady,
    Game,
    GameEnd,
    Level,
    Profile,
    Fun,
    ChooseMode,
    TabletChild,
    TabletStar,
    TabletLogin,
    TabletFinish,
    Medal,
    ChildrenDetail,
    ChildrenMedal
} from './views';
import 'antd/dist/antd.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
    <Router>
        <Routes>
            <Route element={<Login />} path="/"></Route>

            {/* 一体机 */}
            <Route element={<LeaderBoard />} path="/leaderBoard"></Route>
            <Route
                element={<ChooseChildren />}
                path="/chooseChildren/:className"></Route>
            <Route element={<ChooseClass />} path="/chooseClass"></Route>
            <Route element={<ChildrenLogin />} path="/childrenLogin"></Route>
            <Route element={<ChooseAction />} path="/chooseAction"></Route>
            <Route element={<CheckPoint />} path="/checkPoint"></Route>
            <Route element={<GameReady />} path="/gameReady"></Route>
            <Route element={<Game />} path="/game"></Route>
            <Route element={<GameEnd />} path="/gameEnd"></Route>
            <Route element={<Level />} path="/level"></Route>
            <Route element={<Fun />} path="/fun"></Route>
            <Route element={<Profile />} path="/profile"></Route>
            <Route element={<ChooseMode />} path="/chooseMode"></Route>
            <Route element={<Medal />} path="/medal"></Route>

            {/* 平板 */}
            <Route element={<TabletChild />} path="/tabletChild"></Route>
            <Route element={<TabletStar />} path="/tabletStar"></Route>
            <Route element={<TabletLogin />} path="/tabletLogin"></Route>
            <Route element={<TabletFinish />} path="/tabletFinish"></Route>

            {/* 后台管理 */}
            <Route element={<Robot />} path="/robot">
                <Route
                    element={<ChildrenMgmt />}
                    path="/robot/childrenManagement"></Route>
                <Route
                    element={<ChildrenDetail />}
                    path="/robot/childrenDetail/:studentId"></Route>
                <Route
                    element={<ChildrenMedal />}
                    path="/robot/childrenMedal/:studentId"></Route>
            </Route>
        </Routes>
    </Router>
    // </React.StrictMode>
);
