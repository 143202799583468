/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 16:20:18
 * @LastEditTime: 2022-08-21 18:56:40
 * @LastEditors: chenxinyu
 * @Description: 平板-确认游戏儿童页
 * @FilePath: /teacher-system/src/views/tabletChild/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getGameStatus, getTabletChild } from '../../api';
import './index.scss';

const TabletChild: FC = () => {
    const navigate = useNavigate();
    const audioRef = useRef<any>(); // 声音播放实例
    const [child1, setChild1] = useState<any>(); // 儿童1数据

    /**
     * @description 确认选人
     */
    const onConfirm = async () => {
        localStorage.setItem('scoreTimes', '0');
        // 跳转打分页
        navigate('/tabletStar');
    };

    /**
     * @description 平板获取幼儿
     */
    const getChild = async () => {
        const robotId = localStorage.getItem('robotId');
        const res: any = await getTabletChild({ robotId });

        if (res.code === 200 && res.data) {
            const childData = JSON.stringify(res.data);
            localStorage.setItem('tabletChild', childData);
            setChild1(res.data);
        }
    };

    // 拉取幼儿游戏数据
    const getGameStatusNetwork = async () => {
        const tabletChild = localStorage.getItem('tabletChild');
        const childData = JSON.parse(tabletChild!);
        const response: any = await getGameStatus({ studentId: childData.id });
        if (response.code === 200 && response.data.gameStatus === 'over') {
            navigate(-1);
        }
    };

    useEffect(() => {
        // 全屏显示
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        }

        // 拉取幼儿数据
        let time: any;

        time = setInterval(() => {
            // 拉取幼儿数据
            getChild();
        }, 1000);

        // 显示幼儿信息
        const tabletChild = localStorage.getItem('tabletChild');
        const childData = JSON.parse(tabletChild!);
        setChild1(childData);

        return () => {
            clearInterval(time);
        };
    }, []);

    useEffect(() => {
        // 拉取幼儿游戏是否结束
        let time: any;

        time = setInterval(() => {
            getGameStatusNetwork();
        }, 1000);

        return () => {
            clearInterval(time);
        };
    }, []);

    return (
        <div className="tablet-child">
            <div className="title">请确认你要打分的小朋友</div>
            <div className="choosing">
                <div className="choosing-item choosing-item-a">
                    {child1?.studentClass && child1?.name && (
                        <>
                            <img
                                className="choosing-item-img"
                                src={
                                    child1?.profilePhoto
                                        ? 'http://www.ctwlab.com/images/' +
                                          child1?.profilePhoto
                                        : 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F038aa205a405c62a8012197418079e9.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1660795923&t=8818daf40812c0d0c60e9570ac04bc2d'
                                }
                                alt=""
                            />
                        </>
                    )}
                </div>
                <div className="choosing-item-desc">
                    <div className="choosing-item-desc-class">
                        {child1?.studentClass}
                    </div>
                    <div className="choosing-item-desc-student">
                        {child1?.name}
                    </div>
                </div>
            </div>
            <div className="btn" onClick={onConfirm}>
                <div className="btn-icon"></div>
                <div className="btn-word">确认</div>
            </div>
            {/* <div className="back" onClick={onBack}>
                <div className="back-icon"></div>
            </div> */}
            <audio ref={audioRef} autoPlay></audio>
        </div>
    );
};

export default memo(TabletChild);
