/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 21:22:14
 * @LastEditTime: 2022-07-20 10:20:52
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/checkPoint/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */
import { memo, FC } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';

const CheckPoint: FC = () => {
    const navigate = useNavigate();

    /**
     * @description 开始游戏
     */
    const onStart = () => {
        navigate('/childrenLogin');
    };

    return (
        <>
            <div className="check-point"></div>
            <div className="check-point-bg" onClick={onStart}></div>
        </>
    );
};

export default memo(CheckPoint);
