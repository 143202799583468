/*
 * @Author: chenxinyu
 * @Date: 2022-07-11 09:49:16
 * @LastEditTime: 2022-08-06 16:45:26
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/navbar/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { exportExcel, exportExcelStudentGameData, getClasses } from '../../api';
import { message } from 'antd';
import './index.scss';
import Modal from 'antd/lib/modal/Modal';

const NavBar: FC<any> = (props) => {
    const navigate = useNavigate();
    // 是否弹出班级选择弹框
    const [visible, setVisible] = useState<boolean>(false);
    // 列表数据
    const [data, setData] = useState<any>([]);
    /**
     * @description 导出模版数据
     */
    const downloadTemplate = async () => {
        message.success('导出成功！');
        window.location.href =
            'http://www.ctwlab.com/excel/template/student.xlsx';
    };
    // 是否返回幼儿管理页
    const { exportFlag } = props;

    /**
     * @description 导出数据
     */
    const downloadData = async () => {
        message.loading('导出中');
        const res: any = await exportExcel();
        if (res.code === 200) {
            message.success('导出成功！');
            window.location.href = res.data;
        }
    };

    /**
     * @description 导出幼儿游戏数据
     */
    const downloadGameData = async () => {
        const res: any = await getClasses();
        if (res.code === 200) {
            setData(res.data);
        }
        setVisible(true);
    };

    /**
     * @description 导出游戏数据
     */
    const exportClassData = async (studentClass: string) => {
        const res: any = await exportExcelStudentGameData({ studentClass });
        if (res.code === 200) {
            window.location.href = res.data;
            message.success('导出成功！');
            setVisible(false);
        }
    };

    /**
     * @description 登出页面
     */
    const logout = () => {
        localStorage.setItem('robotName', '');
        navigate('/');
    };

    return (
        <nav className="nav">
            <div
                className="nav-title"
                onClick={() => {
                    navigate('/robot/childrenManagement');
                }}>
                <div className="nav-title-icon"></div>
                运动口令机器人信息中心
            </div>
            <div className="nav-link">
                {!exportFlag && (
                    <div
                        className="nav-link-item"
                        onClick={() => {
                            navigate('/robot/childrenManagement');
                        }}>
                        <div className="nav-link-item-icon nav-link-item-person"></div>
                        幼儿管理页
                    </div>
                )}
                {exportFlag && (
                    <>
                        <div
                            className="nav-link-item"
                            onClick={downloadTemplate}>
                            <div className="nav-link-item-icon nav-link-item-download"></div>
                            导出模版
                        </div>
                        <div className="nav-link-item" onClick={downloadData}>
                            <div className="nav-link-item-icon nav-link-item-download"></div>
                            导出幼儿信息
                        </div>
                        <div
                            className="nav-link-item"
                            onClick={downloadGameData}>
                            <div className="nav-link-item-icon nav-link-item-download"></div>
                            导出幼儿游戏数据
                        </div>
                    </>
                )}
                <div className="nav-link-item" onClick={logout}>
                    <div className="nav-link-item-icon nav-link-item-logout"></div>
                    登出
                </div>
            </div>
            <Modal
                title="请选择班级"
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                footer={false}
                width={1000}>
                <div className="export-dialog">
                    <div className="export-dialog-class">
                        {data?.smallClass?.map((item: any, index: number) => {
                            return (
                                <div
                                    className="export-dialog-class-item"
                                    onClick={() => exportClassData(item)}
                                    key={index}>
                                    {item}
                                </div>
                            );
                        })}
                    </div>
                    <div className="export-dialog-class">
                        {data?.middleClass?.map((item: any, index: number) => {
                            return (
                                <div
                                    className="export-dialog-class-item"
                                    onClick={() => exportClassData(item)}
                                    key={index}>
                                    {item}
                                </div>
                            );
                        })}
                    </div>
                    <div className="export-dialog-class">
                        {data?.largeClass?.map((item: any, index: number) => {
                            return (
                                <div
                                    className="export-dialog-class-item"
                                    onClick={() => exportClassData(item)}
                                    key={index}>
                                    {item}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Modal>
        </nav>
    );
};

export default memo(NavBar);
