/*
 * @Author: chenxinyu
 * @Date: 2022-08-06 17:40:26
 * @LastEditTime: 2022-08-06 18:27:14
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/components/backBtn/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */
import { memo, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';

const BackBtn: FC<any> = (props: { backPath: string }) => {
    const navigate = useNavigate();
    const { backPath } = props;

    const onBack = () => {
        if (backPath) {
            navigate(backPath);
        } else {
            navigate(-1);
        }
    };

    return (
        <div className="back" onClick={onBack}>
            <div className="back-icon"></div>
        </div>
    );
};

export default memo(BackBtn);
