/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 09:55:24
 * @LastEditTime: 2022-08-25 20:53:53
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/childrenLogin/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { message } from 'antd';
import { memo, FC, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { gameStatus, getGame5 } from '../../api';
import { Users } from '../../components';
// import { getAction } from '../../api';
import './index.scss';

const ChildrenLogin: FC = () => {
    const navigate = useNavigate();
    const audioRef = useRef<any>(); // 声音播放实例

    /**
     * @description 跳转关卡页面
     */
    const onCheckPoint = () => {
        localStorage.setItem('actionType', 'sports');
        const grade = localStorage.getItem('grade');
        if (grade === '大班') {
            localStorage.setItem('mode', '');
            navigate('/chooseMode');
        } else if (grade === '小班') {
            navigate('/level');
        }
    };

    /**
     * @description 跳转大剧场页面
     */
    const onGoFun = () => {
        localStorage.setItem('mode', '');
        localStorage.setItem('actionType', 'fun');
        navigate('/fun');
    };

    /**
     * @description 跳转合作台页面
     */
    const onGoTogether = async () => {
        const studentId1 = localStorage.getItem('studentId1');
        const studentId2 = localStorage.getItem('studentId2');
        if (!(studentId1 && studentId2)) {
            message.warning('合作台需要两名儿童游戏！');
            return;
        }

        localStorage.setItem('mode', '');
        localStorage.setItem('actionType', 'together');
        const action = localStorage.getItem('actionType');

        const params = {
            action,
            checkPoint: '1',
            studentId: studentId1 + (studentId2 ? ',' + studentId2 : '')
        };
        const res: any = await getGame5(params);
        if (res.code === 200) {
            const data: any = JSON.stringify(res.data);
            // 拉取动作
            localStorage.setItem('actions', data);
            // 拉取批次ID
            const overStatus = localStorage.getItem('overStatus');
            if (!overStatus) {
                localStorage.setItem('batchNumber', res.data[0]['batchNumber']);
            }

            // 跳转游戏准备
            navigate('/gameReady');
        }
    };

    /**
     * @description 获取所有动作
     */
    const getActionNetwork = async () => {
        // const res = await getAction();
    };

    useEffect(() => {
        // 播放音频
        audioRef.current.src = '';
        audioRef.current.src =
            'http://www.ctwlab.com/images/sounds/others/choose-action.mp3';
        audioRef.current.play();

        // 获取动作库
        getActionNetwork();
    }, []);

    /**
     * @description 更改游戏状态为已结束
     */
    // const changeGameStatus = async (studentId: string) => {
    //     const params = {
    //         studentId,
    //         oldGameStatus: 'go',
    //         newGameStatus: 'over'
    //     };
    //     const res: any = await gameStatus(params);
    //     if (res.code === 200) {
    //         // 跳转排行榜
    //         navigate('/leaderBoard');
    //     }
    // };

    const goBack = () => {
        // 返回选人页面
        navigate('/chooseAction');
    };

    return (
        <div className="children-selection">
            <Users />
            <div className="selection">
                <div className="selection-title">选择动作库</div>
                <div
                    className="selection-item selection-base"
                    onClick={onCheckPoint}>
                    <div className="selection-item-title">运动会</div>
                    <div className="selection-base-icon"></div>
                </div>
                <div
                    className="selection-item selection-interest"
                    onClick={onGoFun}>
                    <div className="selection-item-title">大剧场</div>
                    <div className="selection-interest-icon"></div>
                </div>
                <div
                    className="selection-item selection-together"
                    onClick={onGoTogether}>
                    <div className="selection-item-title">合作台</div>
                    <div className="selection-together-icon"></div>
                </div>
            </div>
            <div className="back" onClick={goBack}>
                <div className="back-icon"></div>
            </div>
            <audio ref={audioRef} autoPlay></audio>
        </div>
    );
};

export default memo(ChildrenLogin);
