/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 21:37:01
 * @LastEditTime: 2022-08-21 11:18:36
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/gameReady/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useRef, useState } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { Users } from '../../components';
import { useEffect } from 'react';
import { gameStatus } from '../../api';

const GameReady: FC = () => {
    const navigate = useNavigate();
    const audioRef = useRef<any>(); // 声音播放实例
    const [isSixLevel, setIsSixLevel] = useState<boolean>(false); // 是否第6个关卡

    /**
     * @description 更改游戏状态为已开始
     */
    const changeGameStatus = async (studentId: string) => {
        const params = {
            studentId,
            oldGameStatus: 'ready',
            newGameStatus: 'go'
        };
        const res: any = await gameStatus(params);
        if (res.code === 200) {
        }
    };

    useEffect(() => {
        audioRef.current.src = '';

        // 更改游戏状态为已开始
        const grade = localStorage.getItem('grade');
        if (grade === '大班') {
            const studentId1 = localStorage.getItem('studentId1');
            const studentId2 = localStorage.getItem('studentId2');
            if (studentId1) {
                changeGameStatus(studentId1);
            }
            if (studentId2) {
                changeGameStatus(studentId2);
            }
        }

        // 清空游戏态
        localStorage.setItem('currentActionId', '');
        localStorage.setItem('currentActionIdList1', '');
        localStorage.setItem('currentActionIdList2', '');

        // 2.取出动作
        const actions = localStorage.getItem('actions')!;
        if (actions) {
            const actionList = JSON.parse(actions);
            const checkPointValue = actionList[0].checkPoint;
            const actionLibraryNameValue = actionList[0].actionLibraryName;
            if (actionLibraryNameValue === 'sports' && checkPointValue === 6) {
                setIsSixLevel(true);
                audioRef.current.src =
                    'http://www.ctwlab.com/images/sounds/others/ready-revert.mp3';
                audioRef.current.play();
            } else {
                audioRef.current.src =
                    'http://www.ctwlab.com/images/sounds/others/game-ready.mp3';
                audioRef.current.play();
            }
        }

        // 5秒后进入游戏页面
        setTimeout(() => {
            navigate('/game');
        }, 5000);
    }, []);

    return (
        <div className="game-ready">
            <Users />
            <div className="ready-title">
                {isSixLevel
                    ? `听口令做相反动作，预备，开始`
                    : `听口令做动作，预备，开始！`}
            </div>
            <div className="ready-icon"></div>
            <div className="ready-btm"></div>
            <audio ref={audioRef} autoPlay></audio>
        </div>
    );
};

export default memo(GameReady);
