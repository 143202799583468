/*
 * @Author: chenxinyu
 * @Date: 2022-07-19 13:58:07
 * @LastEditTime: 2022-08-24 19:11:56
 * @LastEditors: chenxinyu
 * @Description: 游戏结束页
 * @FilePath: /teacher-system/src/views/gameEnd/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useState, useRef } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { Users } from '../../components';
import { useEffect } from 'react';
import {
    gameStatus,
    getGameEndStars,
    getNumber,
    initGame,
    postGamePlay
} from '../../api';
import { imgConfig } from '../../config';

const GameEnd: FC = () => {
    const navigate = useNavigate();
    const [actions1, setActions1] = useState<Array<any>>([]); // 动作1
    const [actions2, setActions2] = useState<Array<any>>([]); // 动作2
    const [studentNumber, setStudentNumber] = useState<number>(0); // 参与游戏的儿童数量
    const [showPic, setShowPic] = useState<boolean>(false); // 是否展示动作图片
    const [actionTypeValue, setActionTypeValue] = useState<string>(''); // 动作库
    const audioRef = useRef<any>(); // 声音播放实例
    const resultAudioRef = useRef<any>(); // 语音播放实例

    /**
     * @description 查询游戏次数
     */
    const getPlayTimes = async () => {
        // 儿童id
        const studentId1 = localStorage.getItem('studentId1');
        const studentId2 = localStorage.getItem('studentId2');

        // 上传批次号
        const batchNumber = localStorage.getItem('batchNumber');

        const params = {
            studentId: studentId1,
            batchNumber
        };
        const res: any = await getNumber(params);
        if (res.code === 200) {
            // 再来一局音频
            const mode = localStorage.getItem('mode');
            const times = res.data;
            resultAudioRef.current.src = '';
            if (times === 4) {
                if (mode === 'play_freely_mode') {
                    // 畅玩模式
                    resultAudioRef.current.src =
                        'http://www.ctwlab.com/images/sounds/others/finish-chuangguan.mp3';
                } else if (mode === 'recruit_mode') {
                    // 闯关模式
                    resultAudioRef.current.src =
                        'http://www.ctwlab.com/images/sounds/others/finish-more.mp3';
                }
            } else if (times >= 5) {
                resultAudioRef.current.src =
                    'http://www.ctwlab.com/images/sounds/others/five-times.mp3';
            } else {
                resultAudioRef.current.src =
                    'http://www.ctwlab.com/images/sounds/others/more-game.mp3';
            }

            resultAudioRef.current.play();
        }
    };

    /**
     * @description 上传前数据处理逻辑
     */
    const postResult = async (actionList: any) => {
        // 儿童id
        const studentId1 = localStorage.getItem('studentId1');
        const studentId2 = localStorage.getItem('studentId2');
        const params = actionList[0];
        params.studentId = studentId1;
        // 上传批次号
        const batchNumber = localStorage.getItem('batchNumber');
        params.batchNumber = batchNumber;
        // 动作序列号
        const checkPointSerialNumberList = actionList
            .map((item: any) => {
                return item.checkPointSerialNumber;
            })
            .join(',');
        params.checkPointSerialNumberList = checkPointSerialNumberList;

        // 儿童1 上传结果
        const res1 = await postGamePlay(params);
        if (res1.data) {
            if (studentId2) {
                // 儿童2 上传结果
                params.studentId = studentId2;
                params.batchNumber = batchNumber;
                const res2 = await postGamePlay(params);
            }
        }
    };

    /**
     * @description 更改游戏状态为已结束
     */
    const changeGameStatus = async (studentId: string) => {
        const params = {
            studentId,
            oldGameStatus: 'go',
            newGameStatus: 'over'
        };
        const res: any = await gameStatus(params);
        if (res.code === 200) {
            // 跳转排行榜
            navigate('/leaderBoard');
        }
    };

    /**
     * @description 查询游戏关卡动作打分结果
     */
    const getGameStars = async (ids: string, studentNum: number) => {
        const res: any = await getGameEndStars({ ids });
        if (res.code === 200) {
            if (studentNum === 1) {
                // setStars1(res.data);
                setActions1(res.data);
            } else if (studentNum === 2) {
                // setStars2(res.data);
                setActions2(res.data);
            }
        }
    };

    useEffect(() => {
        // 1.判断游戏儿童数量，显示单人/双人方框
        const studentId1 = localStorage.getItem('studentId1');
        const studentId2 = localStorage.getItem('studentId2');
        if (studentId1) {
            if (studentId2) {
                setStudentNumber(2);
            } else {
                setStudentNumber(1);
            }
        }

        // 2.播放音频
        audioRef.current.src = '';
        audioRef.current.src =
            'http://www.ctwlab.com/images/sounds/others/cheer.mp3';
        audioRef.current.loop = true;
        audioRef.current.play();

        // 3.读取游戏记录，上传服务器
        const actions = localStorage.getItem('actions');
        if (actions) {
            const actionList = JSON.parse(actions);
            setActions1(actionList);
            setActions2(actionList);

            const grade = localStorage.getItem('grade');
            if (grade === '大班') {
                // 4.大班：
                // 查询游戏关卡动作打分结果
                const currentActionIdList1 = localStorage.getItem(
                    'currentActionIdList1'
                );
                const currentActionIdList2 = localStorage.getItem(
                    'currentActionIdList2'
                );
                currentActionIdList1 && getGameStars(currentActionIdList1, 1);
                currentActionIdList2 && getGameStars(currentActionIdList2, 2);
            } else if (grade === '小班') {
                // 4.小班：批量上传游戏结果
                postResult(actionList);
            }

            // 5.判断是否展示动作图片
            if (actions?.length) {
                // 当前动作库
                const actionType = localStorage.getItem('actionType')!;
                setActionTypeValue(actionType);

                // 是否展示动作图片
                const checkPointValue = actionList[0].checkPoint;
                const actionLibraryNameValue = actionList[0].actionLibraryName;
                setShowPic(
                    (checkPointValue < 5 &&
                        actionLibraryNameValue === 'sports') ||
                        actionLibraryNameValue === 'fun' ||
                        actionLibraryNameValue === 'together'
                );
            }
        }

        // 获取游戏次数
        getPlayTimes();

        // 跳转排行榜
        let time: any;

        time = setInterval(() => {
            playOver();
        }, 60000);

        return () => {
            clearInterval(time);
        };
    }, []);

    /**
     * @description 重玩一次
     */
    const playAgain = async () => {
        // 上传儿童数据，供评分
        const robotId = localStorage.getItem('robotId');
        const studentId1 = localStorage.getItem('studentId1');
        const studentId2 = localStorage.getItem('studentId2');
        localStorage.setItem('overStatus', '1');
        const studentIdList = studentId1 + (studentId2 ? ',' + studentId2 : '');
        const params = {
            robotId,
            studentIdList
        };
        const res: any = await initGame(params);
        if (res.code === 200) {
            const isScored = res.data.isScored;
            if (isScored) {
                localStorage.setItem('grade', '大班');
            } else {
                localStorage.setItem('grade', '小班');
            }
        }

        // 跳转页面
        audioRef.current.src = '';
        navigate('/childrenLogin');
    };

    /**
     * @description 结束游戏
     */
    const playOver = () => {
        // 缓存清空
        audioRef.current.src = '';
        localStorage.setItem('class1', '');
        localStorage.setItem('class2', '');
        localStorage.setItem('student1', '');
        localStorage.setItem('student2', '');
        localStorage.setItem('batchNumber', '');
        localStorage.setItem('profilePhoto2', '');
        localStorage.setItem('profilePhoto1', '');
        localStorage.setItem('actions', '');
        localStorage.setItem('batchNumber', '');
        localStorage.setItem('mode', '');
        localStorage.setItem('overStatus', '');

        // 更改游戏状态为已结束
        const studentId1 = localStorage.getItem('studentId1');
        const studentId2 = localStorage.getItem('studentId2');
        studentId1 && changeGameStatus(studentId1);
        studentId2 && changeGameStatus(studentId2);

        localStorage.setItem('studentId1', '');
        localStorage.setItem('studentId2', '');
    };

    /**
     * @description 取出对应动作图片
     */
    const getImg = (action: any) => {
        const actionType = localStorage.getItem('actionType')!;
        const imgUrl = `http://www.ctwlab.com/images/actions/${actionType}/${actionType}-${
            action?.checkPoint
        }-${action?.checkPointSerialNumber}.${
            imgConfig[actionType]?.[action?.checkPoint - 1]?.indexOf(
                action?.checkPointSerialNumber
            ) > -1
                ? 'gif'
                : 'png'
        }`;
        return imgUrl;
    };

    return (
        <>
            <div className="game-end">
                <Users />
                {studentNumber === 1 ? (
                    <>
                        <div className="end-container">
                            {actions1.map((action: any, index: number) => {
                                return (
                                    <div
                                        className="end-container-item"
                                        key={index}>
                                        {showPic ? (
                                            <img src={getImg(action)} alt="" />
                                        ) : (
                                            <div className="end-container-item-word">
                                                {index + 1}
                                            </div>
                                        )}
                                        <div className="end-container-item-star">
                                            {!action.starNum ||
                                            action.starNum === 0 ? (
                                                ''
                                            ) : action.starNum === 1 ? (
                                                <div className="end-container-item-star-item"></div>
                                            ) : action.starNum === 2 ? (
                                                <>
                                                    <div className="end-container-item-star-item"></div>
                                                    <div className="end-container-item-star-item"></div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="end-container-item-star-item"></div>
                                                    <div className="end-container-item-star-item"></div>
                                                    <div className="end-container-item-star-item"></div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="cheer-small-one"></div>
                    </>
                ) : (
                    <>
                        <div className="end-double">
                            <div className="end-double-part">
                                {actions1.map((action: any, index: number) => {
                                    return (
                                        <div
                                            className="end-double-item"
                                            key={index}>
                                            {showPic ? (
                                                <img
                                                    src={getImg(action)}
                                                    alt=""
                                                />
                                            ) : (
                                                <span>{index + 1}</span>
                                            )}
                                            <div className="end-container-item-star">
                                                {!action.starNum ||
                                                action.starNum ===
                                                    0 ? null : action.starNum ===
                                                  1 ? (
                                                    <div className="end-container-item-star-item"></div>
                                                ) : action.starNum === 2 ? (
                                                    <>
                                                        <div className="end-container-item-star-item"></div>
                                                        <div className="end-container-item-star-item"></div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="end-container-item-star-item"></div>
                                                        <div className="end-container-item-star-item"></div>
                                                        <div className="end-container-item-star-item"></div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="end-double-part end-double-part-yellow">
                                {actions2.map((action: any, index: number) => {
                                    return (
                                        <div
                                            className="end-double-item"
                                            key={index}>
                                            {showPic ? (
                                                <img
                                                    src={getImg(action)}
                                                    alt=""
                                                />
                                            ) : (
                                                <span>{index + 1}</span>
                                            )}
                                            <div className="end-container-item-star">
                                                {!action.starNum ||
                                                action.starNum ===
                                                    0 ? null : action.starNum ===
                                                  1 ? (
                                                    <div className="end-container-item-star-item"></div>
                                                ) : action.starNum === 2 ? (
                                                    <>
                                                        <div className="end-container-item-star-item"></div>
                                                        <div className="end-container-item-star-item"></div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="end-container-item-star-item"></div>
                                                        <div className="end-container-item-star-item"></div>
                                                        <div className="end-container-item-star-item"></div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="cheer-small-one"></div>
                    </>
                )}
                <div className="end-btns">
                    <div className="end-btns-item" onClick={playAgain}>
                        <div className="end-btns-item-again"></div>
                        <div className="end-btns-item-word">重玩一次</div>
                    </div>
                    <div
                        className="end-btns-item end-btns-item-left"
                        onClick={playOver}>
                        <div className="end-btns-item-end"></div>
                        <div className="end-btns-item-word">结束游戏</div>
                    </div>
                </div>
                <audio ref={audioRef} autoPlay></audio>
                <audio ref={resultAudioRef} autoPlay></audio>
            </div>
        </>
    );
};

export default memo(GameEnd);
