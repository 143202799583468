/*
 * @Author: chenxinyu
 * @Date: 2022-07-20 12:52:10
 * @LastEditTime: 2022-08-07 13:00:20
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/config.ts
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

export const gifList = [6, 7, 13, 15];

/**
 * @description 图片配置
 */
export const imgConfig: any = {
    sports: [[6, 7, 13, 15], [15, 16], [1], [6, 7, 12, 13]],
    fun: [[], [], [7, 9, 10, 18]]
};
