/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 09:55:24
 * @LastEditTime: 2022-08-15 15:29:05
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/chooseChildren/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { message } from 'antd';
import { memo, FC, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gameStatus, getGameStatus, getStudents } from '../../api';
import { BackBtn } from '../../components';
import './index.scss';

const ChooseChildren: FC = () => {
    const [data, setData] = useState<any>([]); // 列表数据
    const audioRef = useRef<any>(); // 声音播放实例
    const navigate = useNavigate();
    const params = useParams();
    const [selectedId1, setSelectedId1] = useState<string>(''); // 已选幼儿1ID
    const [selectedId2, setSelectedId2] = useState<string>(''); // 已选幼儿2ID

    /**
     * @description 赋值1号小朋友
     */
    const setStudent1 = (person: any) => {
        localStorage.setItem('class1', person.studentClass);
        localStorage.setItem('student1', person.name);
        localStorage.setItem('studentId1', person.id);
        localStorage.setItem('profilePhoto1', person.profilePhoto);
        localStorage.setItem('grade', person.grade);
    };

    /**
     * @description 赋值2号小朋友
     */
    const setStudent2 = (person: any) => {
        localStorage.setItem('class2', person.studentClass);
        localStorage.setItem('student2', person.name);
        localStorage.setItem('studentId2', person.id);
        localStorage.setItem('profilePhoto2', person.profilePhoto);
    };

    /**
     * @description 更改游戏状态为已结束
     */
    const changeGameStatus = async (studentId: string) => {
        console.log('changeGameStatus=', changeGameStatus);
        const params = {
            studentId,
            oldGameStatus: 'ready',
            newGameStatus: 'over'
        };
        const res: any = await gameStatus(params);
        if (res.code === 200) {
        }
    };

    // 拉取幼儿游戏数据
    const getGameStatusNetwork = async (studentId: string) => {
        const response: any = await getGameStatus({ studentId });
        console.log('response.data=', response.data);
        if (response.code === 200 && response.data.gameStatus === 'ready') {
            changeGameStatus(studentId);
        }
    };

    /**
     * @description 选择小朋友
     */
    const goChildrenLogin = (person: any) => {
        // 1.去重逻辑
        const storageStudentId1 = localStorage.getItem('studentId1') + '';
        const storageStudentId2 = localStorage.getItem('studentId2') + '';
        const isChoosing = localStorage.getItem('isChoosing');

        if (
            person.id.toString() === storageStudentId1 ||
            person.id.toString() === storageStudentId2
        ) {
            message.error('这位小朋友已经选择了呦！');
            return;
        }

        // 2.赋值逻辑
        if (isChoosing === '1' || !storageStudentId1) {
            setStudent1(person);
        } else if (isChoosing === '2') {
            setStudent2(person);
        }

        // 3.修改游戏状态
        console.log('isChoosing=', isChoosing);
        console.log('storageStudentId1=', storageStudentId1);
        if (isChoosing === '1' && storageStudentId1) {
            getGameStatusNetwork(storageStudentId1);
        }
        if (isChoosing === '2' && storageStudentId2) {
            getGameStatusNetwork(storageStudentId2);
        }

        // 4.跳回选人页面
        navigate('/chooseAction');
    };

    /**
     * @description 获取所有小朋友
     */
    const getAllStudents = async () => {
        const res: any = await getStudents({ studentClass: params.className });
        console.log(res);
        if (res.code === 200) {
            setData(res.data);
        }
    };

    useEffect(() => {
        // 1.去重逻辑
        const storageStudentId1 = localStorage.getItem('studentId1');
        const storageStudentId2 = localStorage.getItem('studentId2');

        setSelectedId1(storageStudentId1!);
        setSelectedId2(storageStudentId2!);

        if (!storageStudentId1 || !storageStudentId2) {
            // 播放音频
            audioRef.current.src = '';
            audioRef.current.src =
                'http://www.ctwlab.com/images/sounds/others/choose-name.mp3';
            audioRef.current.play();
        }

        getAllStudents();
    }, []);

    return (
        <div className="choose-children">
            <div className="children-title">请选择你的姓名</div>
            <div className="children-container">
                {data?.map((item: any) => {
                    return (
                        <div
                            className="children-item"
                            onClick={() => goChildrenLogin(item)}>
                            <div
                                className={
                                    item.id == selectedId1 ||
                                    item.id == selectedId2
                                        ? 'children-item-icon filter-grey'
                                        : 'children-item-icon'
                                }>
                                <img
                                    className="children-item-icon-img"
                                    src={
                                        item.profilePhoto
                                            ? item.profilePhoto
                                            : 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F038aa205a405c62a8012197418079e9.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1660795923&t=8818daf40812c0d0c60e9570ac04bc2d'
                                    }
                                    alt=""
                                />
                            </div>
                            <div
                                className={
                                    item.id == selectedId1 ||
                                    item.id == selectedId2
                                        ? 'children-item-word filter-grey'
                                        : 'children-item-word'
                                }>
                                {item.name}
                            </div>
                        </div>
                    );
                })}
            </div>
            <BackBtn />
            <audio ref={audioRef} autoPlay></audio>
        </div>
    );
};

export default memo(ChooseChildren);
