/*
 * @Author: chenxinyu
 * @Date: 2022-07-20 10:23:08
 * @LastEditTime: 2022-08-21 12:40:07
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/tabletLogin/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useEffect } from 'react';
import './index.scss';
import { getTabletChild } from '../../api';
import { useNavigate } from 'react-router-dom';

const TabletLogin: FC = () => {
    const navigate = useNavigate();

    /**
     * @description 平板获取幼儿
     */
    const getChild = async () => {
        const robotId = localStorage.getItem('robotId');
        const res: any = await getTabletChild({ robotId });

        if (res.code === 200 && res.data) {
            const childData = JSON.stringify(res.data);
            localStorage.setItem('tabletChild', childData);
            navigate('/tabletChild');
        }
    };

    /**
     * @description 每过一秒获取一次幼儿信息
     */
    useEffect(() => {
        // 拉取幼儿数据
        let time: any;

        time = setInterval(() => {
            // 拉取幼儿数据
            getChild();
        }, 1000);

        // 全屏显示
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        }

        return () => {
            clearInterval(time);
        };
    }, []);

    return (
        <div className="tablet-login">
            <div className="tablet-login-img">请 等 待 游 戏 开 始</div>
        </div>
    );
};

export default memo(TabletLogin);
