/*
 * @Author: chenxinyu
 * @Date: 2022-07-12 10:16:17
 * @LastEditTime: 2022-08-22 20:50:27
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/utils/request.ts
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { message } from 'antd';

/**
 * @description axios 实例，配置有cancelToken 重复请求会取消前序请求，保持最新请求 已完成的请求不会被取消
 * @timeout 5000 ms
 */
const request = axios.create({
    timeout: 0,
    withCredentials: false
    // xsrfCookieName: 'axios-pwebzxbm-csrftoken', // default: XSRF-TOKEN
    // xsrfHeaderName: 'axios-pwebzxbm-x-csrftoken'
});

const pendingRequest = new Map(); // 使用 Map 存储请求 并检测重复

/**
 * @description 根据当前请求的信息，生成请求 Key
 * @param config
 * @returns
 */
const generateReqKey = (config: any) => {
    // GET -> params；POST -> data
    const { method, url, params, data } = config;

    return [
        method,
        url,
        'params={' + qs.stringify(params) + '}',
        'data={' + qs.stringify(data) + '}'
    ].join('&');
};

/**
 * @description 将当前请求信息添加到pendingRequest对象中
 * @param config
 */
const addPendingRequest = (config: AxiosRequestConfig) => {
    const requestKey = generateReqKey(config);

    config.cancelToken =
        config.cancelToken ||
        new axios.CancelToken((cancel) => {
            if (!pendingRequest.has(requestKey)) {
                pendingRequest.set(requestKey, cancel);
            }
        });
};

/**
 * @description 检查是否存在重复请求，若存在则取消已发的请求
 * @param config
 */
const removePendingRequest = (config: AxiosRequestConfig) => {
    const requestKey = generateReqKey(config);

    if (pendingRequest.has(requestKey)) {
        const cancel = pendingRequest.get(requestKey);

        cancel(requestKey);
        pendingRequest.delete(requestKey);
    }
};

/**
 * @description axios 请求拦截器
 */
request.interceptors.request.use(
    (config) => {
        removePendingRequest(config); // 检查是否存在重复请求，若存在则取消已发的请求
        addPendingRequest(config); // 把当前请求添加到pendingRequest对象中

        return config;
    },
    (err) => {
        console.log(`err`, err);

        return Promise.reject(err);
    }
);

/**
 * @description axios 响应拦截器
 */
request.interceptors.response.use(
    (res: any) => {
        removePendingRequest(res.config); // 从pendingRequest对象中移除请求
        // if (res.data.code !== 200 && res.data.msg) {
        //     message.error(res.data.msg);
        // }

        return res.data;
    },
    (err) => {
        removePendingRequest(err.config || {}); // 从pendingRequest对象中移除请求
        if (axios.isCancel(err)) {
            console.log('已取消的重复请求：' + err.message);
        } else {
            // 添加异常处理
            // ESCAPP.loading({ state: 'hide' });
        }

        return Promise.reject(err);
    }
);

export default request;
