/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 16:20:18
 * @LastEditTime: 2022-08-23 18:17:50
 * @LastEditors: chenxinyu
 * @Description: 平板-打分页面
 * @FilePath: /teacher-system/src/views/tabletStar/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getGameStatus, getScoreAction, score } from '../../api';
import { imgConfig } from '../../config';
import './index.scss';

const TabletStar: FC = () => {
    const navigate = useNavigate();
    const audioRef = useRef<any>(); // 声音播放实例
    const [time, setTime] = useState<number>(); // 倒计时
    const [commonTimer, setCommonTimer] = useState<number>(); // 倒计时
    const [selectedItem, setSelectedItem] = useState<number>(); // 选中的打分
    const [currentImg, setCurrentImg] = useState<string>(''); // 当前动作图片
    const [showPic, setShowPic] = useState<boolean>(false); // 是否展示动作图片
    const [playTimes, setPlayTimes] = useState<number | string>(1); // 当前游玩次数
    const [hasStart, setHasStart] = useState<boolean>(false); // 是否游戏已开始

    /**
     * @description 打分结束
     */
    const scoreOver = () => {
        // 清除倒计时
        setTime(0);
        clearInterval(commonTimer);
        // 跳转结束页
        navigate('/tabletFinish');
    };

    /**
     * @description 拉取下一个动作
     */
    const getNextActionOrScoreOver = () => {
        // const scoreTimes = localStorage.getItem('scoreTimes');
        // const times = Number(scoreTimes);

        // 清除倒计时
        setTime(0);
        clearInterval(commonTimer);
        // 打分次数
        // setPlayTimes(times + 1 > 5 ? 5 : times + 1);
        // 定时拉取动作
        getAction(0, 8);
    };

    /**
     * @description 确认打分
     * @param isNotScore 8秒打分倒计时是否结束
     */
    const onScore = async (isNotScore: boolean = false) => {
        // 确认按钮不可点
        if (!(currentImg && selectedItem) && !isNotScore) {
            return;
        }

        // 上传打分结果
        const actionId = sessionStorage.getItem('currentActionId');
        const params = {
            actionId,
            starNum: isNotScore ? 1 : selectedItem
        };
        const res: any = await score(params);
        if (res.code === 200) {
            // 清空当前图片及打分
            setCurrentImg('');
            setSelectedItem(0);

            const scoreTimes = localStorage.getItem('scoreTimes');
            localStorage.setItem(
                'scoreTimes',
                (Number(scoreTimes) + 1).toString()
            );

            // 设置是否最后一个动作
            if (res.data) {
                scoreOver();
            } else {
                getNextActionOrScoreOver();
            }
        }
    };

    /**
     * @description 打分
     */
    const scoreAction = (score: number) => {
        setSelectedItem(score);
    };

    /**
     * @description 倒计时动作
     */
    const timeAction = async (index: number, restTime: number) => {
        let timer: any;

        timer = setInterval(async () => {
            // 倒计时效果
            setTime(restTime);
            restTime--;

            // 倒计时结束
            if (restTime === -1) {
                onScore(true);
                clearInterval(timer);
            }
        }, 1000);

        setCommonTimer(timer);
    };

    /**
     * @description 获取动作Icon
     */
    const findActionIcon = (data: any) => {
        // 动作图片
        const imgUrl = `http://www.ctwlab.com/images/actions/${
            data.actionLibraryName
        }/${data.actionLibraryName}-${data?.checkPoint}-${
            data?.checkPointSerialNumber
        }.${
            imgConfig[data.actionLibraryName]?.[data?.checkPoint - 1]?.indexOf(
                data?.checkPointSerialNumber
            ) > -1
                ? 'gif'
                : 'png'
        }`;

        setCurrentImg(imgUrl);
    };

    /**
     * @description 定时拉取动作
     */
    const getAction = async (index: number, restTime: number) => {
        let timer: any;

        timer = setInterval(async () => {
            // 定时拉取最新动作
            const tabletChild = localStorage.getItem('tabletChild');
            const tabletStudentId = JSON.parse(tabletChild!).id;
            const res: any = await getScoreAction({ tabletStudentId });

            if (res.code === 200) {
                if (res.data) {
                    setHasStart(true);
                    // 拉取到图片
                    clearInterval(timer);
                    // 最新动作
                    // setCurrentAction(res.data);
                    sessionStorage.setItem('currentActionId', res.data.id);
                    // 播放音频
                    audioRef.current.src = '';
                    audioRef.current.src =
                        'http://www.ctwlab.com/images/sounds/others/give-stars.mp3';
                    audioRef.current.play();
                    // 当前动作图片
                    findActionIcon(res.data);
                    // 开始倒计时
                    timeAction(0, 8);

                    setShowPic(
                        (res.data.checkPoint < 5 &&
                            res.data.actionLibraryName === 'sports') ||
                            res.data.actionLibraryName === 'fun' ||
                            res.data.actionLibraryName === 'together'
                    );

                    // 打分次数
                    const scoreTimes = localStorage.getItem('scoreTimes');
                    const times = Number(scoreTimes);

                    setPlayTimes(times + 1 > 5 ? 5 : times + 1);
                } else {
                    // 没有拉取到图片
                    setCurrentImg('');
                    setPlayTimes('');
                }
            } else {
                // 没有拉取到图片
                setCurrentImg('');
                setPlayTimes('');
            }
        }, 1000);
    };

    // 拉取幼儿游戏数据
    const getGameStatusNetwork = async () => {
        const tabletChild = localStorage.getItem('tabletChild');
        const childData = JSON.parse(tabletChild!);
        const response: any = await getGameStatus({ studentId: childData.id });
        if (response.code === 200 && response.data.gameStatus === 'over') {
            navigate('/tabletLogin');
        }
    };

    useEffect(() => {
        // 定时拉取动作
        getAction(0, 8);

        // 全屏显示
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        }

        return clearInterval(commonTimer);
    }, []);

    useEffect(() => {
        // 拉取幼儿游戏是否结束
        let time: any;

        time = setInterval(() => {
            getGameStatusNetwork();
        }, 1000);

        return () => {
            clearInterval(time);
        };
    }, []);

    return (
        <div className="tablet-star">
            <div className="title"></div>
            <div className="star">
                <div className="star-img">
                    {showPic ? (
                        currentImg && <img src={currentImg} alt="" />
                    ) : hasStart ? (
                        <span>{playTimes}</span>
                    ) : null}
                </div>
                <div className="star-score">
                    <div
                        className={
                            selectedItem === 3
                                ? 'star-score-item star-score-item-three-stars-selected'
                                : 'star-score-item star-score-item-three-stars'
                        }
                        onClick={() => {
                            scoreAction(3);
                        }}></div>
                    <div
                        className={
                            selectedItem === 2
                                ? 'star-score-item star-score-item-two-stars-selected'
                                : 'star-score-item star-score-item-two-stars'
                        }
                        onClick={() => {
                            scoreAction(2);
                        }}></div>
                    <div
                        className={
                            selectedItem === 1
                                ? 'star-score-item star-score-item-one-star-selected'
                                : 'star-score-item star-score-item-one-star'
                        }
                        onClick={() => {
                            scoreAction(1);
                        }}></div>
                </div>
            </div>
            <div className="btn" onClick={() => onScore(false)}>
                {currentImg && selectedItem ? (
                    <div className="btn-icon"></div>
                ) : (
                    <div className="btn-icon-gray"></div>
                )}
                <div className="btn-word">确认</div>
            </div>
            <div className="game-time">
                <div className="game-time-icon"></div>
                {time}
            </div>
            {/* <div className="back" onClick={onBack}>
                <div className="back-icon"></div>
            </div> */}
            <audio ref={audioRef} autoPlay></audio>
        </div>
    );
};

export default memo(TabletStar);
