/*
 * @Author: chenxinyu
 * @Date: 2022-08-01 19:47:02
 * @LastEditTime: 2022-08-06 17:09:57
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/childrenDetail/pageConfig.ts
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */
/*
 * @Author: chenxinyu
 * @Date: 2022-07-12 16:28:52
 * @LastEditTime: 2022-07-13 16:22:51
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/childrenMgmt/pageConfig.ts
 * Copyright (C) 2021 chenxinyu. All rights reserved.
 */

// 关卡
export const checkPointConfig = [
    '',
    '关卡一',
    '关卡二',
    '关卡三',
    '关卡四',
    '关卡五',
    '关卡六'
];
