/*
 * @Author: chenxinyu
 * @Date: 2022-07-11 11:20:29
 * @LastEditTime: 2022-08-06 16:44:13
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/robot/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../navbar';
// import { renderRoutes } from 'react-router-config';

const Contact: FC = (props: any) => {
    const [showExportFlag, setShowExportFlag] = useState<boolean>(false);
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        if (pathname.indexOf('childrenManagement') > -1) {
            setShowExportFlag(true);
        } else {
            setShowExportFlag(false);
        }
    }, [pathname]);

    return (
        <div className="home">
            <Navbar exportFlag={showExportFlag} />
            <div>
                <Outlet />
            </div>
        </div>
    );
};

export default memo(Contact);
