/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 16:20:18
 * @LastEditTime: 2022-08-24 21:21:24
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/chooseAction/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCompair, initGame } from '../../api';
import { BackBtn } from '../../components';
import './index.scss';

const ChooseAction: FC = () => {
    const navigate = useNavigate();
    const audioRef = useRef<any>(); // 声音播放实例
    const [child1, setChild1] = useState<any>(); // 儿童1数据
    const [child2, setChild2] = useState<any>(); // 儿童2数据
    const [startFlag, setStartFlag] = useState<boolean>(false); // 是否可以开始游戏

    /**
     * @description 上传儿童数据，供评分
     */
    const postChildrenInfo = async () => {
        const robotId = localStorage.getItem('robotId');
        const studentId1 = localStorage.getItem('studentId1');
        const studentId2 = localStorage.getItem('studentId2');
        const studentIdList = studentId1 + (studentId2 ? ',' + studentId2 : '');
        const params = {
            robotId,
            studentIdList
        };
        const res: any = await initGame(params);
        if (res.code === 200) {
            const isScored = res.data.isScored;
            if (isScored) {
                localStorage.setItem('grade', '大班');
            } else {
                localStorage.setItem('grade', '小班');
            }
        }
    };

    /**
     * @description 选择儿童
     */
    const selectChildren = (isChoosing: string) => {
        localStorage.setItem('isChoosing', isChoosing);
        navigate('/chooseClass');
    };

    /**
     * @description 开始游戏
     */
    const onStart = async () => {
        // 两人游戏，比较年级，返回较小年级
        const studentId1 = localStorage.getItem('studentId1');
        const studentId2 = localStorage.getItem('studentId2');
        if (studentId1 && studentId2) {
            const res: any = await getCompair({ studentId1, studentId2 });
            if (res.code === 200) {
                localStorage.setItem('grade', res.data);
                if (res.data === '大班') {
                    // postChildrenInfo();
                }
            }
        }
        postChildrenInfo();

        // 跳转动作库选择页
        startFlag && navigate('/childrenLogin');
    };

    /**
     * @description 配置儿童1信息
     */
    const setUser1 = () => {
        const className = localStorage.getItem('class1');
        const student = localStorage.getItem('student1');
        const profilePhoto = localStorage.getItem('profilePhoto1');
        setChild1({ className, student, profilePhoto });
    };

    /**
     * @description 配置儿童2信息
     */
    const setUser2 = () => {
        const className = localStorage.getItem('class2');
        const student = localStorage.getItem('student2');
        const profilePhoto = localStorage.getItem('profilePhoto2');
        setChild2({ className, student, profilePhoto });
    };

    useEffect(() => {
        // 全屏显示
        // if (document.documentElement.requestFullscreen) {
        //     document.documentElement.requestFullscreen();
        // }

        // 回显user1 和 user2的信息
        setUser1();
        setUser2();

        // 清空连续游玩标识
        localStorage.setItem('overStatus', '');

        // 开始游戏按钮可点击状态
        const student1 = localStorage.getItem('student1');
        const student2 = localStorage.getItem('student2');

        if (!student1 && !student2) {
            // 播放音频
            audioRef.current.src = '';
            audioRef.current.src =
                'http://www.ctwlab.com/images/sounds/others/choose-child.mp3';
            audioRef.current.play();

            setStartFlag(false);
        } else {
            setStartFlag(true);
        }
    }, []);

    return (
        <div className="choose-action">
            <div className="choosing">
                <div className="choosing-block">
                    <div
                        className="choosing-item choosing-item-a"
                        onClick={() => selectChildren('1')}>
                        {child1?.className && child1?.student && (
                            <>
                                <img
                                    className="choosing-item-img"
                                    src={
                                        child1?.profilePhoto
                                            ? child1?.profilePhoto
                                            : 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F038aa205a405c62a8012197418079e9.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1660795923&t=8818daf40812c0d0c60e9570ac04bc2d'
                                    }
                                    alt=""
                                />
                            </>
                        )}
                    </div>
                    <img
                        className="choosing-item-click"
                        src="http://www.ctwlab.com/images/game/click.gif"
                        alt=""
                    />
                    <div className="choosing-item-desc">
                        <div className="choosing-item-desc-class">
                            {child1?.className}
                        </div>
                        <div className="choosing-item-desc-student">
                            {child1?.student}
                        </div>
                    </div>
                </div>
                <div className="choosing-block">
                    <div
                        className="choosing-item choosing-item-b"
                        onClick={() => selectChildren('2')}>
                        {child2?.className && child2?.student && (
                            <>
                                <img
                                    className="choosing-item-img"
                                    src={
                                        child2?.profilePhoto
                                            ? child2?.profilePhoto
                                            : 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F038aa205a405c62a8012197418079e9.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1660795923&t=8818daf40812c0d0c60e9570ac04bc2d'
                                    }
                                    alt=""
                                />
                            </>
                        )}
                    </div>
                    <img
                        className="choosing-item-click"
                        src="http://www.ctwlab.com/images/game/click.gif"
                        alt=""
                    />
                    <div className="choosing-item-desc">
                        <div className="choosing-item-desc-class">
                            {child2?.className}
                        </div>
                        <div className="choosing-item-desc-student">
                            {child2?.student}
                        </div>
                    </div>
                </div>
            </div>
            <div className="btn" onClick={onStart}>
                {startFlag ? (
                    <div className="btn-icon"></div>
                ) : (
                    <div className="btn-icon-gray"></div>
                )}
                <div className="btn-word">开始</div>
            </div>
            <BackBtn backPath="/leaderBoard" />
            <audio ref={audioRef} autoPlay></audio>
        </div>
    );
};

export default memo(ChooseAction);
