/*
 * @Author: chenxinyu
 * @Date: 2022-07-12 11:47:38
 * @LastEditTime: 2022-08-06 16:23:14
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/api/apiList.ts
 * Copyright (C) 2021 chenxinyu. All rights reserved.
 */
export const baseURL = '//www.ctwlab.com/api';
// export const baseURL = '//101.132.33.159/api';

/**
 * @description 后台管理
 */
// 用户登录
export const loginURL = `${baseURL}/user/login`;

// 拉取幼儿列表
export const getStudentListURL = `${baseURL}/student`;

// 导出全部数据
export const exportExcelUrl = `${baseURL}/student/exportExcelUrl`;

// 上传学生信息
export const importUrl = `${baseURL}/student/import`;

// 修改学生信息
export const editStudentListURL = `${baseURL}/student/file`;

// 幼儿游戏记录接口
export const checkPointRecordURL = `${baseURL}/checkPoint/record`;

// 按照班级导出幼儿游戏数据接口
export const exportExcelStudentGameDataURL = `${baseURL}/student/exportExcelStudentGameData`;

/**
 * @description 游戏
 */

// 两人游戏，比较年级，返回较小年级
export const compairURL = `${baseURL}/game`;

// 查询动作库
export const actionURL = `${baseURL}/game/action`;

// 查询大剧场关卡数
export const funURL = `${baseURL}/game/fun`;

// 根据动作库关卡选择5个游戏动作
export const game5URL = `${baseURL}/game/game5`;

// 每个游戏批次关卡游戏结束，返回游戏次数
export const numberURL = `${baseURL}/game/number`;

// 每个游戏结束上传游戏批次号，动作库，关卡，关卡序列号
export const playBatch = `${baseURL}/game/playBatch`;

// 排行榜
export const rankURL = `${baseURL}/game/rank`;

// 幼儿个人信息游戏结果,返回完成的游戏动作数量
export const resultURL = `${baseURL}/report/result`;

// 1.查询运动会关卡数
// 2.查询运动会畅玩/闯关模式完成关卡的星星数量
export const sportsURL = `${baseURL}/game/sports`;

// 查询合作台关卡数
export const togetherURL = `${baseURL}/game/together`;

/**
 * @description 大班模式
 */

// 更新幼儿的游戏状态：ready,go,over
export const gameStatusURL = `${baseURL}/game/gameStatus`;

// 上传幼儿信息，返回年级；判断是否进入打分模式，以及游戏信息初始化(开始游戏)
export const initURL = `${baseURL}/game/init`;

// 1.每个游戏结束上传游戏批次号，动作库(运动会还有模式：畅玩play_freely_mode,闯关recruit_mode)，关卡，关卡序列号
// 2.查询游戏关卡动作打分结果
export const playURL = `${baseURL}/game/play`;

// 1.每个动作游戏打分
// 2.获取待打分的关卡动作
export const scoreURL = `${baseURL}/game/play/score`;

// 获取幼儿运动会闯关模式最新关卡
export const checkPointUrl = `${baseURL}/game/action/sports/checkPoint`;

/**
 * @description 勋章
 */

// 查询勋章
export const medalURL = `${baseURL}/report/medal`;

/**
 * @description 平板
 */

// 平板获取幼儿
export const tabletURL = `${baseURL}/game/init/tablet`;

/**
 * @description 班级
 */

// 查询班级
export const classURL = `${baseURL}/class`;
