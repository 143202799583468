/*
 * @Author: chenxinyu
 * @Date: 2022-07-17 09:37:11
 * @LastEditTime: 2022-08-21 17:46:19
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/leaderBoard/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { memo, FC, useState, useEffect, useRef } from 'react';
import './index.scss';
import { getRank } from '../../api';
import { useNavigate } from 'react-router-dom';

const LeaderBoard: FC = () => {
    const [largeShowList, setLargeShowList] = useState<any>([]); // 大班展示数据
    const [middleShowList, setMiddleShowList] = useState<any>([]); // 中班展示数据
    const [smallShowList, setSmallShowList] = useState<any>([]); // 小班展示数据
    const [firstFlag, setFirstFlag] = useState<boolean>(true); // 第一次请求
    const audioRef = useRef<any>(); // 声音播放实例
    const [canStart, setCanStart] = useState<boolean>(true); // 是否可以开始游戏
    const navigate = useNavigate();

    /**
     * @description 拉取列表
     */
    const getList = async (grade: string) => {
        const res: any = await getRank({ grade });
        if (res.code === 200) {
            setFirstFlag(false);
            const data = res.data;
            if (grade === '大班') {
                // 赋值头10个数组
                const largeListString = JSON.stringify(data);
                sessionStorage.setItem('largeList', largeListString);
                setLargeShowList(data.slice(0, 10));
            } else if (grade === '中班') {
                // 赋值头10个数组
                const middleListString = JSON.stringify(data);
                sessionStorage.setItem('middleList', middleListString);
                setMiddleShowList(data.slice(0, 10));
            } else if (grade === '小班') {
                // 赋值头10个数组
                const smallListString = JSON.stringify(data);
                sessionStorage.setItem('smallList', smallListString);
                setSmallShowList(data.slice(0, 10));
            }
        }
    };

    /**
     * @description 获取新的幼儿列表
     */
    const getLargeNewList = () => {
        // 取出轮询的当前数
        const largeCurrent = sessionStorage.getItem('largeCurrent');
        const largeList = JSON.parse(sessionStorage.getItem('largeList')!);

        // 是否需要循环展示
        const largeNumber =
            largeList.length === 10 ? 0 : Math.floor(largeList.length / 10);
        if (largeNumber > 0) {
            let tempCurrent = Number(largeCurrent) + 1;
            if (Number(largeNumber) < tempCurrent) {
                tempCurrent = 0;
            }

            sessionStorage.setItem('largeCurrent', tempCurrent.toString());

            const tempList = largeList.slice(
                10 * tempCurrent,
                10 * tempCurrent + 10
            );
            setLargeShowList(tempList);
        }
    };

    /**
     * @description 获取新的幼儿列表
     */
    const getMiddleNewList = () => {
        // 取出轮询的当前数
        const middleCurrent = sessionStorage.getItem('middleCurrent');
        const middleList = JSON.parse(sessionStorage.getItem('middleList')!);

        // 是否需要循环展示
        const middleNumber =
            middleList.length === 10 ? 0 : Math.floor(middleList.length / 10);
        if (middleNumber > 0) {
            let tempCurrent = Number(middleCurrent) + 1;
            if (Number(middleNumber) < tempCurrent) {
                tempCurrent = 0;
            }

            sessionStorage.setItem('middleCurrent', tempCurrent.toString());

            const tempList = middleList.slice(
                10 * tempCurrent,
                10 * tempCurrent + 10
            );
            setMiddleShowList(tempList);
        }
    };

    /**
     * @description 获取新的幼儿列表
     */
    const getSmallNewList = () => {
        // 取出轮询的当前数
        const smallCurrent = sessionStorage.getItem('smallCurrent');
        const smallList = JSON.parse(sessionStorage.getItem('smallList')!);

        // 是否需要循环展示
        const smallNumber =
            smallList.length === 10 ? 0 : Math.floor(smallList.length / 10);
        if (smallNumber > 0) {
            let tempCurrent = Number(smallCurrent) + 1;
            if (Number(smallNumber) < tempCurrent) {
                tempCurrent = 0;
            }

            sessionStorage.setItem('smallCurrent', tempCurrent.toString());

            const tempList = smallList.slice(
                10 * tempCurrent,
                10 * tempCurrent + 10
            );
            setSmallShowList(tempList);
        }
    };

    /**
     * @description 清空儿童信息
     */
    const clearChildInfo = () => {
        localStorage.setItem('class1', '');
        localStorage.setItem('class2', '');
        localStorage.setItem('profileId', '');
        localStorage.setItem('profilePhoto2', '');
        localStorage.setItem('studentId2', '');
        localStorage.setItem('student1', '');
        localStorage.setItem('profilePhoto1', '');
        localStorage.setItem('studentId1', '');
        localStorage.setItem('actions', '');
        localStorage.setItem('student2', '');
        localStorage.setItem('batchNumber', '');
    };

    useEffect(() => {
        // 清空儿童信息
        clearChildInfo();

        // 全屏显示
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        }
        // else if (document.documentElement.msRequestFullscreen) {
        //     document.documentElement.msRequestFullscreen();
        // } else if (document.documentElement.mozRequestFullScreen) {
        //     document.documentElement.mozRequestFullScreen();
        // } else if (document.documentElement.webkitRequestFullScreen) {
        //     document.documentElement.webkitRequestFullScreen();
        // }

        // 拉取排行榜数据;
        getList('小班');
        getList('中班');
        getList('大班');

        // 跳转排行榜
        let time: any;

        time = setInterval(() => {
            getLargeNewList();
            getMiddleNewList();
            getSmallNewList();
        }, 5000);

        return () => {
            clearInterval(time);
        };
    }, []);

    /**
     * @description 开始游戏
     */
    const onStart = () => {
        // 不可重复点击
        if (!canStart) {
            return;
        }
        setCanStart(false);

        // 按钮点击音
        audioRef.current.src = '';
        audioRef.current.src =
            'http://www.ctwlab.com/images/sounds/others/click.mp3';
        audioRef.current.play();
        setTimeout(() => {
            navigate('/chooseAction');
        }, 1500);
    };

    /**
     * @description 点击标题
     */
    const onTitleClicked = () => {
        // window.location.reload();
    };

    /**
     * @description 返回按钮
     */
    const onBack = () => {
        clearChildInfo();
        navigate('/');
    };

    return (
        <div className="leaderBoard">
            <div className="title" onClick={onTitleClicked}></div>
            {!firstFlag && (
                <div className="container">
                    <div className="leader-class leader-class-small">
                        {smallShowList.map((item: any, index: number) => {
                            return (
                                <div className="leader-item" key={index}>
                                    {item.profilePhoto && (
                                        <img src={item.profilePhoto} alt="" />
                                    )}
                                    <div>{item.studentClass}&nbsp;&nbsp;</div>
                                    <div>{item.name}</div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="leader-class leader-class-middle leader-left">
                        {middleShowList.map((item: any, index: number) => {
                            return (
                                <div className="leader-item" key={index}>
                                    {item.studentName ? (
                                        <>
                                            <span>{index + 1}.</span>
                                            {item.profilePhoto && (
                                                <img
                                                    src={item.profilePhoto}
                                                    alt=""
                                                />
                                            )}
                                            <div>
                                                {item.studentClass}&nbsp;&nbsp;
                                            </div>
                                            <div>{item.studentName}</div>
                                            <img
                                                className="leader-item-star"
                                                src="http://www.ctwlab.com/images/game/star.png"
                                                alt=""
                                            />
                                            <span>{`x${item.starNum}`}</span>
                                        </>
                                    ) : (
                                        <>
                                            {item.profilePhoto && (
                                                <img
                                                    src={item.profilePhoto}
                                                    alt=""
                                                />
                                            )}
                                            <div>
                                                {item.studentClass}&nbsp;&nbsp;
                                            </div>
                                            <div>{item.name}</div>
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className="leader-class leader-class-large leader-left">
                        {largeShowList.map((item: any, index: number) => {
                            return (
                                <div className="leader-item" key={index}>
                                    <span>{index + 1}.</span>
                                    {item.profilePhoto && (
                                        <img src={item.profilePhoto} alt="" />
                                    )}
                                    <div>{item.studentClass}&nbsp;&nbsp;</div>
                                    <div>{item.studentName}</div>
                                    <img
                                        className="leader-item-star"
                                        src="http://www.ctwlab.com/images/game/star.png"
                                        alt=""
                                    />
                                    <span>{`x${item.starNum}`}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            <div className="btn">
                <div className="btn-start" onClick={onStart}></div>
            </div>
            <audio ref={audioRef} autoPlay></audio>
            {/* <div className="back-icon" onClick={onBack}></div> */}
        </div>
    );
};

export default memo(LeaderBoard);
