/*
 * @Author: chenxinyu
 * @Date: 2022-08-03 16:36:48
 * @LastEditTime: 2022-08-03 16:41:56
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/childrenMedal/config.ts
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

/**
 * @description 运动库标题
 */
export const actionTitle = {
    sports: '运动会',
    fun: '大剧场',
    together: '合作台'
};

/**
 * @description 勋章标题
 */
export const medalTitle = [
    '活力小宝贝',
    '阳光小宝贝',
    '健康小宝贝',
    '运动小能手',
    '体育小健将',
    '全能小冠军',
    '模仿小天才',
    '表演小明星',
    '武林小高手',
    '最佳小搭档'
];
