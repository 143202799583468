/*
 * @Author: chenxinyu
 * @Date: 2022-08-02 20:24:21
 * @LastEditTime: 2022-08-17 17:56:41
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/childrenMedal/index.tsx
 * Copyright (C) 2022 chenxinyu. All rights reserved.
 */

import { message, Table } from 'antd';
import { memo, FC, useState, useEffect } from 'react';
import './index.scss';
import { getMedal } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { Users } from '../../components';
import { medalTitle } from './config';

const ChildrenMedal: FC = () => {
    const [loading, setLoading] = useState<boolean>(false); // 是否loading
    const [data, setData] = useState<any>([]); // 列表数据
    const navigate = useNavigate();
    const params = useParams();

    /**
     * @description 拉取列表
     */
    const getList = async () => {
        setLoading(true);
        const studentId = params.studentId;
        const res: any = await getMedal({ studentId });
        setLoading(false);
        setData(res.data);
    };

    const getColumns: any = () => {
        return [
            {
                title: '动作库',
                key: 'serialNumber',
                align: 'center',
                dataIndex: 'serialNumber',
                render: (value: any, row: any, index: number) => {
                    const obj = {
                        children: value,
                        props: { rowSpan: 0 }
                    };
                    if (index === 0) {
                        obj.props.rowSpan = 6;
                        obj.children = '运动会';
                    }
                    if (index === 6) {
                        obj.props.rowSpan = 3;
                        obj.children = '大剧场';
                    }
                    if (index === 9) {
                        obj.props.rowSpan = 1;
                        obj.children = '合作台';
                    }

                    return obj;
                }
            },
            {
                title: '勋章',
                key: 'grade',
                align: 'center',
                children: [],
                dataIndex: 'grade',
                render: (_: any, record: any, index: number) => {
                    return medalTitle[index];
                }
            },
            {
                title: '是否获得',
                key: 'studentClass',
                align: 'center',
                children: [],
                dataIndex: 'studentClass',
                render: (_: any, record: any, index: number) => {
                    return record.isUnlock ? (
                        <img
                            className="green-finish"
                            src="http://www.ctwlab.com/images/game/green-finish.png"
                            alt=""
                        />
                    ) : (
                        ''
                    );
                }
            }
        ];
    };

    useEffect(() => {
        getList();
    }, []);

    useEffect(() => {
        message.config({
            duration: 2,
            maxCount: 1
        });
        // const table = document.getElementsByClassName('ant-table-row');

        // if (table.length > 0) {
        //     const rowList = Array.from(table);
        //     rowList.forEach((row: any, index) => {
        //         if (index % 2 !== 0) {
        //             row.style.backgroundColor = '#f9f9f9';
        //         } else {
        //             row.style.backgroundColor = '#ffffff';
        //         }
        //     });
        // }
    }, []);

    /**
     * @description 跳转个人信息页
     */
    const getAction = (actionType: string) => {
        sessionStorage.setItem('actionType', actionType);
        const studentId = params.studentId;
        navigate('/robot/childrenDetail/' + studentId);
    };

    return (
        <div className="detail">
            <div className="detail-bg">
                <Users notDetail />
                <div className="detail-bg-tabs">
                    <div className="detail-bg-tabs-item detail-bg-tabs-item-border">
                        勋章库
                    </div>
                    <div
                        className="detail-bg-tabs-item"
                        onClick={() => getAction('sports')}>
                        运动会
                    </div>
                    <div
                        className="detail-bg-tabs-item"
                        onClick={() => getAction('fun')}>
                        大剧场
                    </div>
                    <div
                        className="detail-bg-tabs-item"
                        onClick={() => getAction('together')}>
                        合作台
                    </div>
                </div>
            </div>
            <Table
                rowKey={(record) => record?.serialNumber}
                dataSource={data}
                loading={loading}
                bordered
                // pagination={{
                //     current: 1,
                //     pageSize: 10,
                //     total: 10,
                //     showTotal: (num) => `共 ${num} 条`,
                //     showSizeChanger: false
                //     // onChange: (page) => handlePageChange(page)
                // }}
                pagination={false}
                scroll={{ y: 500 }}
                size="small"
                columns={getColumns()}
            />
        </div>
    );
};

export default memo(ChildrenMedal);
