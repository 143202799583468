/*
 * @Author: chenxinyu
 * @Date: 2022-07-12 15:00:17
 * @LastEditTime: 2022-08-11 21:16:28
 * @LastEditors: chenxinyu
 * @Description: 幼儿管理页面
 * @FilePath: /teacher-system/src/views/childrenMgmt/index.tsx
 * Copyright (C) 2021 chenxinyu. All rights reserved.
 */

import { Button, Form, Input, message, Modal, Table } from 'antd';
import { memo, FC, useState, useEffect } from 'react';
import { CHILDREN_MGMT_COLUMNS } from './pageConfig';
import './index.scss';
import { deleteStudent, editStudent, getStudentList } from '../../api';
import axios from 'axios';
import { importUrl, editStudentListURL } from '../../api/apiList';
import { useNavigate } from 'react-router-dom';

const ChildrenMgmt: FC = () => {
    const [loading, setLoading] = useState<boolean>(false); // 是否loading
    const [data, setData] = useState<any>([]); // 列表数据
    const [editForm] = Form.useForm(); // 编辑页表单对象
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false); // 是否展示编辑对话框
    const [id, setId] = useState<string>('');
    const [studentIcon, setStudentIcon] = useState<any>();
    const navigate = useNavigate();

    /**
     * @description 拉取列表
     */
    const getList = async () => {
        setLoading(true);
        const res = await getStudentList({});
        setLoading(false);
        setData(res.data);
    };

    /**
     * @description 删除网络请求
     */
    const deletePerson = async (id: string) => {
        const res: any = await deleteStudent({ id });
        if (res.code === 200) {
            message.success('删除成功!');
            getList();
        }
    };

    /**
     * @description 删除
     */
    const onDelete = (e: any, record: any) => {
        const { id, name } = record;
        Modal.confirm({
            title: '删除',
            content: `确认删除${name}?`,
            okText: '确认',
            cancelText: '取消',
            onOk: () => deletePerson(id)
        });
    };

    /**
     * @description 修改
     */
    const onEdit = (e: any, record: any) => {
        setId(record.id);
        editForm.setFieldsValue({ serialNumber: record.serialNumber });
        editForm.setFieldsValue({ grade: record.grade });
        editForm.setFieldsValue({ studentClass: record.studentClass });
        editForm.setFieldsValue({ name: record.name });

        setIsModalVisible(true);
    };

    /**
     * @description 查看幼儿详情
     */
    const onGoDetail = (record: any) => {
        console.log('record=', record);
        localStorage.setItem('class1', record.studentClass);
        localStorage.setItem('student1', record.name);
        localStorage.setItem('studentId1', record.id);
        localStorage.setItem('profilePhoto1', record.profilePhoto);

        sessionStorage.setItem('actionType', 'sports');
        navigate('/robot/childrenDetail/' + record.id);
    };

    /**
     * @description 保存-网络请求
     */
    const saveNetwork = async (params: any) => {
        const res: any = await editStudent(params);
        if (res.code === 200) {
            setIsModalVisible(false);
            // message.success('修改成功!');
            getList();
        }
    };

    /**
     * @description 保存方法
     */
    const saveBtn = async () => {
        const formValues = editForm.getFieldsValue();
        const { serialNumber, grade, studentClass, name } = formValues;

        let formData = new FormData();
        formData.append('file', studentIcon);
        formData.append('serialNumber', serialNumber);
        formData.append('grade', grade);
        formData.append('studentClass', studentClass);
        formData.append('name', name);

        message.loading('修改中');

        const url = editStudentListURL + '/' + id;
        const res: any = await axios.put(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (res.data.code === 200) {
            message.success('修改成功！');
            handleCancel();
            navigate('/');
        }
    };

    /**
     * @description 备注修改
     */
    const onRemarkChange = (e: { target: { value: string } }, record: any) => {
        const value = e.target.value;
        record.profilePhoto = record.profilePhoto.replaceAll(
            'http://www.ctwlab.com/images/',
            ''
        );
        record.remark = value;
        saveNetwork(record);
    };

    const getColumns: any = () => {
        return [
            ...CHILDREN_MGMT_COLUMNS,
            {
                title: '头像',
                key: 'profilePhoto',
                align: 'center',
                children: [],
                dataIndex: 'profilePhoto',
                render: (_: any, record: any) => {
                    return (
                        <img
                            alt=""
                            className="student-img"
                            src={
                                record.profilePhoto
                                    ? record.profilePhoto
                                    : 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fup.enterdesk.com%2Fedpic_source%2Fa0%2F86%2Fcf%2Fa086cf6bbd1bb41376188b69619611ad.jpg&refer=http%3A%2F%2Fup.enterdesk.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1660365070&t=808504d394067a68066cac85f8f6c154'
                            }
                        />
                    );
                }
            },
            {
                title: '详情',
                key: 'detail',
                align: 'center',
                children: [],
                width: 100,
                dataIndex: 'detail',
                render: (_: any, record: any) => {
                    return (
                        <div
                            className="detail-btn"
                            onClick={() => {
                                onGoDetail(record);
                            }}>
                            查看
                        </div>
                    );
                }
            },
            {
                title: '操作',
                key: 'operation',
                align: 'center',
                children: [],
                width: 150,
                dataIndex: 'operation',
                render: (_: any, record: any) => {
                    return (
                        <div className="detail-container">
                            <div
                                className="detail-btn"
                                onClick={(e) => {
                                    onEdit(e, record);
                                }}>
                                修改
                            </div>
                            <div
                                className="detail-btn del-btn"
                                onClick={(e) => {
                                    onDelete(e, record);
                                }}>
                                删除
                            </div>
                        </div>
                    );
                }
            },
            {
                title: '备注',
                key: 'remark',
                children: [],
                align: 'center',
                dataIndex: 'remark',
                render: (_: any, record: any) => {
                    return (
                        <div className="remark">
                            <input
                                className="remark-input"
                                placeholder="请输入备注"
                                defaultValue={record.remark}
                                onChange={(e) => onRemarkChange(e, record)}
                            />
                            <div className="remark-icon"></div>
                        </div>
                    );
                }
            }
        ];
    };

    /**
     * @description 上传文件到服务器
     */
    const postFileToServer = async (formData: any) => {
        const res: any = await axios.post(importUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (res.data.code === 200) {
            message.success('上传成功！');
            navigate('/');
        }
    };

    /**
     * @description 上传文件
     */
    const postFile = () => {
        const refs: any = document.getElementById('file_upload')!;
        const file = refs.files[0];
        let formData = new FormData();
        formData.append('file', file);
        message.loading('上传中……');
        postFileToServer(formData);
    };

    /**
     * @description 修改头像
     */
    const postIcon = () => {
        const refs: any = document.getElementById('icon_upload')!;
        const file = refs.files[0];
        setStudentIcon(file);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        getList();
    }, []);

    useEffect(() => {
        message.config({
            duration: 2,
            maxCount: 1
        });

        const table = document.getElementsByClassName('ant-table-row');

        if (table.length > 0) {
            const rowList = Array.from(table);
            rowList.forEach((row: any, index) => {
                if (index % 2 !== 0) {
                    row.style.backgroundColor = '#f9f9f9';
                } else {
                    row.style.backgroundColor = '#ffffff';
                }
            });
        }
    }, []);

    return (
        <div className="children">
            <div className="children-bg">
                <div className="children-bg-title">幼儿管理</div>
                <div className="children-bg-sub">
                    上传本地幼儿列表*excel文件
                </div>
                <input
                    className="children-bg-input"
                    type="file"
                    id="file_upload"
                    onChange={postFile}
                />
                <div className="children-bg-upload">
                    <div className="children-bg-upload-icon"></div>上传文件
                </div>
            </div>
            <Table
                rowKey={(record) => record?.serialNumber}
                dataSource={data}
                loading={loading}
                bordered
                // pagination={{
                //     current: 1,
                //     pageSize: 10,
                //     total: 10,
                //     showTotal: (num) => `共 ${num} 条`,
                //     showSizeChanger: false
                //     // onChange: (page) => handlePageChange(page)
                // }}
                pagination={false}
                scroll={{ y: 315 }}
                size="small"
                columns={getColumns()}
            />
            {/* <div className="children-bottom">
                <div className="children-btn">
                    <div className="children-btn-icon"></div>保存修改
                </div>
            </div> */}

            <Modal
                title="修改"
                visible={isModalVisible}
                width={500}
                footer={null}
                onCancel={handleCancel}>
                <Form
                    form={editForm}
                    name="editForm"
                    style={{ padding: '10px 0 20px' }}>
                    <Form.Item
                        name="serialNumber"
                        label="编号："
                        rules={[
                            {
                                required: true,
                                message: '编号字段为空，请填写后再提交'
                            }
                        ]}>
                        <Input
                            placeholder="请输入编号"
                            maxLength={50}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item
                        name="grade"
                        label="年级："
                        rules={[
                            {
                                required: true,
                                message: '年级字段为空，请填写后再提交'
                            }
                        ]}>
                        <Input
                            placeholder="请输入年级"
                            maxLength={50}
                            allowClear
                        />
                    </Form.Item>

                    <Form.Item
                        name="studentClass"
                        label="班级："
                        rules={[
                            {
                                required: true,
                                message: '班级字段为空，请填写后再提交'
                            }
                        ]}>
                        <Input
                            placeholder="请输入班级"
                            maxLength={50}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="名字："
                        rules={[
                            {
                                required: true,
                                message: '名字为空，请填写后再提交'
                            }
                        ]}>
                        <Input
                            placeholder="请输入名字"
                            maxLength={50}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item
                        name="profilePhoto"
                        label="头像"
                        rules={[
                            {
                                required: true,
                                message: '头像为空，请上传后再提交'
                            }
                        ]}>
                        <input
                            type="file"
                            id="icon_upload"
                            onChange={postIcon}
                        />
                    </Form.Item>
                </Form>
                <div className="form-footer">
                    <Button type="primary" onClick={saveBtn}>
                        保存
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default memo(ChildrenMgmt);
