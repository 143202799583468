/*
 * @Author: chenxinyu
 * @Date: 2022-07-12 16:28:52
 * @LastEditTime: 2022-07-13 16:22:51
 * @LastEditors: chenxinyu
 * @Description:
 * @FilePath: /teacher-system/src/views/childrenMgmt/pageConfig.ts
 * Copyright (C) 2021 chenxinyu. All rights reserved.
 */

import { ColumnsType } from 'antd/lib/table';

// 购车卡列表
export const CHILDREN_MGMT_COLUMNS: ColumnsType<any> = [
    {
        title: '幼儿编号',
        key: 'serialNumber',
        align: 'center',
        width: 100,
        dataIndex: 'serialNumber'
    },
    {
        title: '年级',
        key: 'grade',
        align: 'center',
        children: [],
        width: 100,
        dataIndex: 'grade'
    },
    {
        title: '班级',
        key: 'studentClass',
        align: 'center',
        children: [],
        width: 100,
        dataIndex: 'studentClass'
    },
    {
        title: '名字',
        key: 'name',
        align: 'center',
        children: [],
        dataIndex: 'name'
    }
];
