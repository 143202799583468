/*
 * @Author: chenxinyu
 * @Date: 2022-07-12 15:00:17
 * @LastEditTime: 2022-08-17 17:55:31
 * @LastEditors: chenxinyu
 * @Description: 幼儿详情页面
 * @FilePath: /teacher-system/src/views/childrenDetail/index.tsx
 * Copyright (C) 2021 chenxinyu. All rights reserved.
 */

import { message, Table } from 'antd';
import { memo, FC, useState, useEffect } from 'react';
import './index.scss';
import { getCheckPointRecord } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { Users } from '../../components';
import { checkPointConfig } from './pageConfig';

const ChildrenDetail: FC = () => {
    const [loading, setLoading] = useState<boolean>(false); // 是否loading
    const [data, setData] = useState<any>([]); // 列表数据
    const [currentTab, setCurrentTab] = useState<string>('sports'); // 当前模式
    const navigate = useNavigate();
    const params = useParams();

    /**
     * @description 拉取列表
     */
    const getList = async (actionType: string) => {
        const studentId = params.studentId;
        const res: any = await getCheckPointRecord({
            studentId,
            action: actionType
        });
        setData(res.data);
    };

    const getColumns: any = () => {
        return [
            {
                title: '序号',
                key: 'serialNumber',
                align: 'center',
                width: 100,
                dataIndex: 'serialNumber',
                render: (_: any, record: any, index: number) => {
                    return index + 1;
                }
            },
            {
                title: '关卡',
                key: 'checkPoint',
                align: 'center',
                children: [],
                dataIndex: 'checkPoint',
                render: (_: any, record: any) => {
                    return <span>{checkPointConfig[record.checkPoint]}</span>;
                }
            },
            {
                title: '动作',
                key: 'checkPointDescribe',
                align: 'center',
                children: [],
                dataIndex: 'checkPointDescribe'
            },
            {
                title: '是否完成',
                key: 'name',
                align: 'center',
                children: [],
                dataIndex: 'name',
                render: (_: any, record: any) => {
                    return record.isComplete ? (
                        <img
                            className="green-finish"
                            src="http://www.ctwlab.com/images/game/green-finish.png"
                            alt=""
                        />
                    ) : (
                        ''
                    );
                }
            },
            {
                title: '星星数（畅玩）',
                key: 'profilePhoto',
                align: 'center',
                children: [],
                dataIndex: 'profilePhoto',
                render: (_: any, record: any) => {
                    return (
                        <div>
                            {record.starNum === 1 ? (
                                <img
                                    alt=""
                                    className="student-img"
                                    src="http://www.ctwlab.com/images/game/star.png"
                                />
                            ) : record.starNum === 2 ? (
                                <>
                                    <img
                                        alt=""
                                        className="student-img"
                                        src="http://www.ctwlab.com/images/game/star.png"
                                    />
                                    <img
                                        alt=""
                                        className="student-img"
                                        src="http://www.ctwlab.com/images/game/star.png"
                                    />
                                </>
                            ) : record.starNum === 3 ? (
                                <>
                                    <img
                                        alt=""
                                        className="student-img"
                                        src="http://www.ctwlab.com/images/game/star.png"
                                    />
                                    <img
                                        alt=""
                                        className="student-img"
                                        src="http://www.ctwlab.com/images/game/star.png"
                                    />
                                    <img
                                        alt=""
                                        className="student-img"
                                        src="http://www.ctwlab.com/images/game/star.png"
                                    />
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    );
                }
            },
            {
                title: '星星数（闯关）',
                key: 'detail',
                align: 'center',
                children: [],
                dataIndex: 'detail',
                render: (_: any, record: any) => {
                    return (
                        <div>
                            {record.starRecruitNum === 1 ? (
                                <img
                                    alt=""
                                    className="student-img"
                                    src="http://www.ctwlab.com/images/game/star.png"
                                />
                            ) : record.starRecruitNum === 2 ? (
                                <>
                                    <img
                                        alt=""
                                        className="student-img"
                                        src="http://www.ctwlab.com/images/game/star.png"
                                    />
                                    <img
                                        alt=""
                                        className="student-img"
                                        src="http://www.ctwlab.com/images/game/star.png"
                                    />
                                </>
                            ) : record.starRecruitNum === 3 ? (
                                <>
                                    <img
                                        alt=""
                                        className="student-img"
                                        src="http://www.ctwlab.com/images/game/star.png"
                                    />
                                    <img
                                        alt=""
                                        className="student-img"
                                        src="http://www.ctwlab.com/images/game/star.png"
                                    />
                                    <img
                                        alt=""
                                        className="student-img"
                                        src="http://www.ctwlab.com/images/game/star.png"
                                    />
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    );
                }
            }
        ];
    };

    useEffect(() => {
        message.config({
            duration: 2,
            maxCount: 1
        });
        const table = document.getElementsByClassName('ant-table-row');

        if (table.length > 0) {
            const rowList = Array.from(table);
            rowList.forEach((row: any, index) => {
                if (index % 2 !== 0) {
                    row.style.backgroundColor = '#f9f9f9';
                } else {
                    row.style.backgroundColor = '#ffffff';
                }
            });
        }
    }, []);

    /**
     * @description 跳转勋章库
     */
    const goMedal = () => {
        const studentId = params.studentId;
        navigate('/robot/childrenMedal/' + studentId);
    };

    /**
     * @description 跳转对应动作库游戏记录
     */
    const goAction = (actionType: string) => {
        setCurrentTab(actionType);
        getList(actionType);
    };

    /**
     * @description 页面初始化
     */
    useEffect(() => {
        const actionType = sessionStorage.getItem('actionType')!;
        getList(actionType);
    }, []);

    return (
        <div className="detail">
            <div className="detail-bg">
                <Users notDetail />
                <div className="detail-bg-tabs">
                    <div className="detail-bg-tabs-item" onClick={goMedal}>
                        勋章库
                    </div>
                    <div
                        className={
                            currentTab === 'sports'
                                ? 'detail-bg-tabs-item detail-bg-tabs-item-border'
                                : 'detail-bg-tabs-item'
                        }
                        onClick={() => goAction('sports')}>
                        运动会
                    </div>
                    <div
                        className={
                            currentTab === 'fun'
                                ? 'detail-bg-tabs-item detail-bg-tabs-item-border'
                                : 'detail-bg-tabs-item'
                        }
                        onClick={() => goAction('fun')}>
                        大剧场
                    </div>
                    <div
                        className={
                            currentTab === 'together'
                                ? 'detail-bg-tabs-item detail-bg-tabs-item-border'
                                : 'detail-bg-tabs-item'
                        }
                        onClick={() => goAction('together')}>
                        合作台
                    </div>
                </div>
            </div>
            <Table
                rowKey={(record) => record?.serialNumber}
                dataSource={data}
                loading={loading}
                bordered
                // pagination={{
                //     current: 1,
                //     pageSize: 10,
                //     total: 10,
                //     showTotal: (num) => `共 ${num} 条`,
                //     showSizeChanger: false
                //     // onChange: (page) => handlePageChange(page)
                // }}
                pagination={false}
                scroll={{ y: 500 }}
                size="small"
                columns={getColumns()}
            />
        </div>
    );
};

export default memo(ChildrenDetail);
